import React, { useContext, useEffect, useState } from "react";
import UserContext from '../../UserContext';
import { toast } from "react-hot-toast";

export default function CatWidget(props) {

    const userContext = useContext(UserContext);

    const [categories, setCategories] = useState([]);
    const [changecat, setChangeCat] = useState([]);


    useEffect(() => {
        fetch('https://api.poddies-kochbuch.de/categories', {
            headers: {
                'Authorization': userContext.user.loginAns.token_type + " " + userContext.user.loginAns.access_token,
            },
        })
            .then(res => res.json())
            .then(json => {
                setCategories(json);
                setChangeCat(structuredClone(json));
            });
    }, [userContext.user.loginAns.access_token, userContext.user.loginAns.token_type])

    function handleChange(e, kat, i) {
        if(e.target.checked) {
            let testcat = ([...changecat.slice(0,i),
                {
                    ...changecat[i],
                    recipes: [
                        ...changecat[i].recipes,
                        [props.rec]
                    ]
                },
              ...changecat.slice(i+1)]) 
            setChangeCat(testcat);
        }
        else {
            const findval = (element) => element[0] === props.rec;
            let pos = changecat[i].recipes.findIndex(findval);
            let testcat = ([...changecat.slice(0,i),
                {
                    ...changecat[i],
                    recipes: [
                        ...changecat[i].recipes.splice(0, pos),
                        ...changecat[i].recipes.splice(pos+1),
                    ]
                },
              ...changecat.slice(i+1)]) 
            setChangeCat(testcat);
        }
    }

    function handleCancel() {
        setChangeCat(structuredClone(categories));
        props.hideCat()
    }

    function handleSave() {
        changecat.forEach((element,i) => {
            element.recipes.forEach((elementrec) => {
                if(!categories[i].recipes.some(e => e[0] === elementrec[0])) {
                    let newurl = 'https://api.poddies-kochbuch.de/categories/' + element.idCategory + "/" + elementrec[0];

                    fetch(newurl, {
                        method: "POST",
                        headers: {
                            'Authorization': userContext.user.loginAns.token_type + " " + userContext.user.loginAns.access_token,
                            'Content-Type': 'application/json'
                        }})
                    .then(res => {
                        if(res.status !== 200) {
                            toast.error("Failed to add Recipe to Category");
                        }
                    })
                }
            })
        })

        categories.forEach((element,i) => {
            element.recipes.forEach((elementrec) => {
                if(!changecat[i].recipes.some(e => e[0] === elementrec[0])) {
                    let newurl = 'https://api.poddies-kochbuch.de/categories/' + element.idCategory + "/" + elementrec[0];

                    fetch(newurl, {
                        method: "DELETE",
                        headers: {
                            'Authorization': userContext.user.loginAns.token_type + " " + userContext.user.loginAns.access_token,
                            'Content-Type': 'application/json'
                        }})
                    .then(res => {
                        if(res.status !== 200) {
                            toast.error("Failed to add Recipe to Category");
                        }
                    })
                }
            })
        })

        setCategories(structuredClone(changecat));
        props.hideCat();
    }

    let cat = changecat.map((el,i) => {
        return (
            <div className="catitem" key={el.idCategory}>
                <input type="checkbox" id={el.catname} value={el.catname} checked={el.recipes.some(e => e[0] === props.rec)}
                    onChange={(e) => handleChange(e, el.idCategory, i)}
                ></input>
                <label htmlFor={el.catname}>{el.catname}</label>
            </div>
        )
    })

    return (
        <>
            <div className="catwidgetback" style={{visibility: !props.visible ? "hidden" : "initial"}}>
                <div className="catwidget">
                    {cat}
                    <div className="buttonbar">
                        <button onClick={e => handleCancel()}>Cancel</button>
                        <button onClick={e => handleSave()}>Save</button>

                    </div>
                </div>
            </div>
        </>
    )


}





/*
class CatWidget extends React.Component {
    static contextType = UserContext

    constructor(props) {
        super(props)
        this.state = {categories: [],
                      changecat: []}
    }

    componentDidMount() {
        const userData = this.context.user

        fetch('https://api.poddies-kochbuch.de/categories', {
            headers: {
                'Authorization': userData.loginAns.token_type + " " + userData.loginAns.access_token,
            },
        })
            .then(res => res.json())
            .then(json => {
                this.setState({categories: json})
                this.setState({changecat: structuredClone(json)})
            });
    }

    handleChange(e, kat, i) {
        if(e.target.checked) {
            let testcat = ([...this.state.changecat.slice(0,i),
                {
                    ...this.state.changecat[i],
                    recipes: [
                        ...this.state.changecat[i].recipes,
                        [this.props.rec]
                    ]
                },
              ...this.state.changecat.slice(i+1)]) 
            this.setState({changecat: testcat
            });
        }
        else {
            const findval = (element) => element[0] === this.props.rec;
            let pos = this.state.changecat[i].recipes.findIndex(findval);
            let testcat = ([...this.state.changecat.slice(0,i),
                {
                    ...this.state.changecat[i],
                    recipes: [
                        ...this.state.changecat[i].recipes.splice(0, pos),
                        ...this.state.changecat[i].recipes.splice(pos+1),
                    ]
                },
              ...this.state.changecat.slice(i+1)]) 
            this.setState({changecat: testcat
            });
        }
    }

    handleCancel() {
        this.setState({changecat: structuredClone(this.state.categories)})
        this.props.hideCat()
    }

    handleSave() {
        const userData = this.context.user

        this.state.changecat.forEach((element,i) => {
            element.recipes.forEach((elementrec) => {
                if(!this.state.categories[i].recipes.some(e => e[0] === elementrec[0])) {
                    let newurl = 'https://api.poddies-kochbuch.de/categories/' + element.idCategory + "/" + elementrec[0];

                    fetch(newurl, {
                        method: "POST",
                        headers: {
                            'Authorization': userData.loginAns.token_type + " " + userData.loginAns.access_token,
                            'Content-Type': 'application/json'
                        }})
                    .then(res => {
                        if(res.status !== 200) {
                            toast.error("Failed to add Recipe to Category");
                        }
                    })
                }
            })
        })

        this.state.categories.forEach((element,i) => {
            element.recipes.forEach((elementrec) => {
                if(!this.state.changecat[i].recipes.some(e => e[0] === elementrec[0])) {
                    let newurl = 'https://api.poddies-kochbuch.de/categories/' + element.idCategory + "/" + elementrec[0];

                    fetch(newurl, {
                        method: "DELETE",
                        headers: {
                            'Authorization': userData.loginAns.token_type + " " + userData.loginAns.access_token,
                            'Content-Type': 'application/json'
                        }})
                    .then(res => {
                        if(res.status !== 200) {
                            toast.error("Failed to add Recipe to Category");
                        }
                    })
                }
            })
        })

        this.setState({categories: structuredClone(this.state.changecat)})
        this.props.hideCat();
    }

    render() {
        let cat = this.state.changecat.map((el,i) => {
            return (
                <div className="catitem" key={el.idCategory}>
                    <input type="checkbox" id={el.catname} value={el.catname} checked={el.recipes.some(e => e[0] === this.props.rec)}
                        onChange={(e) => this.handleChange(e, el.idCategory, i)}
                    ></input>
                    <label htmlFor={el.catname}>{el.catname}</label>
                </div>
            )
        })

        return (
            <>
                <div className="catwidgetback" style={{visibility: !this.props.visible ? "hidden" : "initial"}}>
                    <div className="catwidget">
                        {cat}
                        <div className="buttonbar">
                            <button onClick={e => this.handleCancel()}>Cancel</button>
                            <button onClick={e => this.handleSave()}>Save</button>

                        </div>
                    </div>
                </div>
            </>
        )
    }

}

export default CatWidget;
*/