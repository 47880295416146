import React from "react";
import { useNavigate } from "react-router-dom";
import "./registerpage.scss"
import toast from 'react-hot-toast';

export default function RegisterPage() {

    const navigate = useNavigate();

    function handleRegister(e) {
        e.preventDefault();
        if(e.target.pw1.value === e.target.pw2.value) {
            let  url = "https://api.poddies-kochbuch.de/register?username=" + e.target.username.value + "&password=" + e.target.pw1.value + "&email=" + e.target.email.value
            fetch(url, {
                method: 'POST',
                headers: {
                  'Accept': 'application/json',
                }})
            .then(res => {
                switch(res.status) {
                    case 200:
                        toast.success("User created successfully");
                        navigate("/login");
                        break;
                    case 470: //Status Code general error
                        toast.error("User creation failed");
                        break;
                    case 471: //Status Code username taken
                        e.target.username.setCustomValidity("Username is already taken");
                        break;
                    case 472: //Status Code email already used
                        e.target.email.setCustomValidity("E-Mail has already been used");
                        break;
                    default:
                }
            });
        }
        else {
            e.target.pw2.setCustomValidity("Second Password has to be the same as first Password");
        }
    }

    return (
        <>
            <div className="registercontent">
                <div className="registercard">
                    <form name="register" onSubmit={(e) => handleRegister(e)}>
                        <div className="form_wrap">
                            <div className="input_wrap">
                                <input id="username" name="username" type="text" placeholder="Username" required ></input>
                                <label htmlFor="username">Username</label>
                            </div>
                            <div className="input_wrap">
                                <input id="email" name="email" type="email" placeholder="E-Mail" required ></input>
                                <label htmlFor="email">E-Mail</label>
                            </div>
                            <div className="input_wrap">
                                <input id="pw1" name="pw1" type="password" placeholder="Password" required ></input>
                                <label htmlFor="pw1">Password</label>
                            </div>
                            <div className="input_wrap">
                                <input id="pw2" name="pw2" type="password" placeholder="Password" required ></input>
                                <label htmlFor="pw2">Password</label>
                            </div>
                            <div className="input_wrap">
                                <input className='registerbutton' type="submit" value="Register" />
                            </div>

                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}




/*
class RegisterPage extends React.Component {

    handleRegister(e) {
        e.preventDefault();
        if(e.target.pw1.value === e.target.pw2.value) {
            let  url = "https://api.poddies-kochbuch.de/register?username=" + e.target.username.value + "&password=" + e.target.pw1.value + "&email=" + e.target.email.value
            fetch(url, {
                method: 'POST',
                headers: {
                  'Accept': 'application/json',
                }})
            .then(res => {
                switch(res.status) {
                    case 200:
                        toast.success("User created successfully");
                        this.props.navigate("/login");
                        break;
                    case 470: //Status Code general error
                        toast.error("User creation failed");
                        break;
                    case 471: //Status Code username taken
                        e.target.username.setCustomValidity("Username is already taken");
                        break;
                    case 472: //Status Code email already used
                        e.target.email.setCustomValidity("E-Mail has already been used");
                        break;
                    default:
                }
            });
        }
        else {
            e.target.pw2.setCustomValidity("Second Password has to be the same as first Password");
        }
    }

    render() {
        return (
            <>
                <div className="registercontent">
                    <div className="registercard">
                        <form name="register" onSubmit={(e) => this.handleRegister(e)}>
                            <div className="form_wrap">
                                <div className="input_wrap">
                                    <input id="username" name="username" type="text" placeholder="Username" required ></input>
                                    <label htmlFor="username">Username</label>
                                </div>
                                <div className="input_wrap">
                                    <input id="email" name="email" type="email" placeholder="E-Mail" required ></input>
                                    <label htmlFor="email">E-Mail</label>
                                </div>
                                <div className="input_wrap">
                                    <input id="pw1" name="pw1" type="password" placeholder="Password" required ></input>
                                    <label htmlFor="pw1">Password</label>
                                </div>
                                <div className="input_wrap">
                                    <input id="pw2" name="pw2" type="password" placeholder="Password" required ></input>
                                    <label htmlFor="pw2">Password</label>
                                </div>
                                <div className="input_wrap">
                                    <input className='registerbutton' type="submit" value="Register" />
                                </div>

                            </div>
                        </form>
                    </div>
                </div>
            </>
        )
    }
}

function withHooks(Component) {
    return props => <RegisterPage navigate={useNavigate()} {...props} />;
}

export default withHooks(RegisterPage)
*/