import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';

export default function CategoryCard(props) {

    useEffect(() => {

        window.onclick = function(event) {
            if (!event.target.matches('.optionsbtn')) {
                var dropdowns = document.getElementsByClassName("options");
                var i;
                for (i = 0; i < dropdowns.length; i++) {
                    var openDropdown = dropdowns[i];
                    if (openDropdown.classList.contains('show')) {
                        openDropdown.classList.remove('show');
                    }
                }
            }
        }

    }, []);

    let path = "https://api.poddies-kochbuch.de/recipes/" + props.firstrec + "/image"
    return(
        <NavLink className="categorycard" to={"/kategorien/" + props.id}>
            <img src={path} alt="" className="categoryimage"></img>
            <div className='reccardbar'>
                <p className="categoryname">{props.name}</p>
                <div className='optionsmenu'>
                    <button className='optionsbtn' onClick={(e) => showOptionsDropdown(e)}>
                    </button>
                    <div className='options' id={"optionsDropdown" + props.id}>
                        <button onClick={(e) => handleEdit(e)}>Edit</button>
                        <button onClick={(e) => handleDelete(e)}>Delete</button>
                    </div>
                </div>
            </div>
            <p className="catshortdesc">{props.count} Rezepte</p>
        </NavLink>
    );

    function handleDelete(e) {
        props.handleDelete(e, props.id);
    }

    function handleEdit(e) {
        props.handleEdit(e, props.id);
    }

    function showOptionsDropdown(e) {
        e.preventDefault();
        var dropdowns = document.getElementsByClassName("options");
        var i;
        for (i = 0; i < dropdowns.length; i++) {
            var openDropdown = dropdowns[i];
            if (openDropdown.classList.contains('show')) {
                openDropdown.classList.remove('show');
            }
        }
        document.getElementById("optionsDropdown" + props.id).classList.toggle("show");
    }
}


/*
class CategoryCard extends React.Component{
    static contextType = UserContext

    componentDidMount() {
        window.onclick = function(event) {
            if (!event.target.matches('.optionsbtn')) {
                var dropdowns = document.getElementsByClassName("options");
                var i;
                for (i = 0; i < dropdowns.length; i++) {
                    var openDropdown = dropdowns[i];
                    if (openDropdown.classList.contains('show')) {
                        openDropdown.classList.remove('show');
                    }
                }
            }
        }
    }

    handleDelete(e) {
        this.props.handleDelete(e, this.props.id);
    }

    handleEdit(e) {
        this.props.handleEdit(e, this.props.id);
    }

    showOptionsDropdown(e) {
        e.preventDefault();
        var dropdowns = document.getElementsByClassName("options");
        var i;
        for (i = 0; i < dropdowns.length; i++) {
            var openDropdown = dropdowns[i];
            if (openDropdown.classList.contains('show')) {
                openDropdown.classList.remove('show');
            }
        }
        document.getElementById("optionsDropdown" + this.props.id).classList.toggle("show");
    }

    render() {
        let path = "https://api.poddies-kochbuch.de/recipes/" + this.props.firstrec + "/image"
        return(
            <NavLink className="categorycard" to={"/kategorien/" + this.props.id}>
                <img src={path} alt="" className="categoryimage"></img>
                <div className='reccardbar'>
                    <p className="categoryname">{this.props.name}</p>
                    <div className='optionsmenu'>
                        <button className='optionsbtn' onClick={(e) => this.showOptionsDropdown(e)}>
                        </button>
                        <div className='options' id={"optionsDropdown" + this.props.id}>
                            <button onClick={(e) => this.handleEdit(e)}>Edit</button>
                            <button onClick={(e) => this.handleDelete(e)}>Delete</button>
                        </div>
                    </div>
                </div>
                <p className="catshortdesc">{this.props.count} Rezepte</p>
            </NavLink>
        );
    }
}

export default CategoryCard;
*/