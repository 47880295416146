import React, { useContext, useEffect, useState } from 'react'
import './login.scss'
import { NavLink, useNavigate } from 'react-router-dom';
import UserContext from '../../UserContext';

export default function Login(props) {

    const userContext = useContext(UserContext);
    const navigate = useNavigate();

    const [errmsg,setErrmsg] = useState("");

    useEffect(() => {
        if(userContext.user.isLoggedIn) {
            navigate(-1);
        }
    }, [navigate, userContext.user.isLoggedIn])


    async function handleLogin(e) {
        e.preventDefault();
        let res = await props.handleLogin(e.target.username.value, e.target.password.value);
        if(res === 200) {
            navigate(-1);
        }
        else {
            setErrmsg("*Username or Password is wrong")
        }
    }


    return (
        <div className='logincontent'>
            <div className='logincard'>
                <form name="login" onSubmit={(e) => handleLogin(e)}> 
                    <div className='form_wrap'>
                        <div className='input_wrap'>
                            <input id="username" name="username" type="text" placeholder="Username" required/>
                            <label htmlFor="username">Username</label>
                        </div>
                        <div className='input_wrap'>
                            <input id="password" name="password" type='password' placeholder="Username" required/>
                            <label htmlFor="password">Password</label>
                        </div>
                        <div className='input_wrap error'>
                            <span>{errmsg}</span>
                        </div>
                        <div className='input_wrap'>
                            <input className='loginbutton' type='submit' value="Login" />
                        </div>
                        <div >
                            <NavLink to="/register">Register</NavLink>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )

} 




/*
class Login extends React.Component {
    static contextType = UserContext

    constructor(props) {
        super(props);
        this.state = {
            errmsg: ""
        };

    }

    componentDidMount() {
        const userData = this.context.user

        if(userData.isLoggedIn) {
            this.props.navigate(-1)
        }
    }

    async handleLogin(e) {
        e.preventDefault();
        let res = await this.props.handleLogin(e.target.username.value, e.target.password.value);
        if(res === 200) {
            this.props.navigate(-1);
        }
        else {
            this.setState({errmsg: "*Username or Password is wrong"})
        }
    }

    render() {
        return (
            <div className='logincontent'>
                <div className='logincard'>
                    <form name="login" onSubmit={(e) => this.handleLogin(e)}> 
                        <div className='form_wrap'>
                            <div className='input_wrap'>
                                <input id="username" name="username" type="text" placeholder="Username" required/>
                                <label htmlFor="username">Username</label>
                            </div>
                            <div className='input_wrap'>
                                <input id="password" name="password" type='password' placeholder="Username" required/>
                                <label htmlFor="password">Password</label>
                            </div>
                            <div className='input_wrap error'>
                                <span>{this.state.errmsg}</span>
                            </div>
                            <div className='input_wrap'>
                                <input className='loginbutton' type='submit' value="Login" />
                            </div>
                            <div >
                                <NavLink to="/register">Register</NavLink>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }

}

function withHooks(Component) {
    return props => <Login navigate={useNavigate()} {...props} />;
}

export default withHooks(Login);
*/