/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef, useState } from 'react';
import {createRef} from 'react';
import { useNavigate, useParams } from "react-router-dom";
import "./recipepage.scss";
import UserContext from '../../UserContext';
import ContentEditable from 'react-contenteditable';
import toast from 'react-hot-toast';
import sanitizeHtml from "sanitize-html";
import CatWidget from './catwidget';

export default function RecipePage(props) {

    const userContext = useContext(UserContext);
    const navigate = useNavigate();
    const params = useParams();
    const nameRef = useRef("");

    const [recipe, setRecipe] = useState({});
    const [changerec, setChangeRec] = useState({});
    const [fetched, setFetched] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [units, setUnits] = useState([]);
    const [tobedel, setToBeDel] = useState([]);
    const [showCat, setShowCat] = useState(false);
    const [persNote, setPersNote] = useState("");
    const [persNoteEdit, setPersNoteEdit] = useState("");
    const [pndisabled, setPndisabled] = useState(true);
    const [draggItem, setDragItem] = useState({});

    const fileInput = createRef();

    useEffect(() => {

        const fetchData = async () => {
            const url = 'https://api.poddies-kochbuch.de/recipes/' + params.recipe

            if(!fetched) {
                fetch(url)
                    .then(res => res.json())
                    .then(json => {
                        setRecipe({...json[0], ingredients: json[0].ingredients.toSorted((a, b) => a.sortNum - b.sortNum)});
                        setChangeRec(structuredClone({...json[0], ingredients: json[0].ingredients.toSorted((a, b) => a.sortNum - b.sortNum)}));
                        
                        if(json[0].name === "") {
                            handleEdit();
                        }
                    });
            }

            const unitsUrl = 'https://api.poddies-kochbuch.de/units'
            const unitsResponse = await fetch(unitsUrl);
            const unitsJson = await unitsResponse.json();
            setUnits(unitsJson);

            if(userContext.user.isLoggedIn) {
                const persNoteUrl = 'https://api.poddies-kochbuch.de/recipes/' + params.recipe + "/pers_note";
                const persNoteResponse = await fetch(persNoteUrl, {
                                                    headers: {
                                                        'Authorization': userContext.user.loginAns.token_type + " " + userContext.user.loginAns.access_token,
                                                    },
                                                });
                if(persNoteResponse.status === 200) {
                    const persNoteJson = await persNoteResponse.json();
                    try {
                        setPersNote(persNoteJson[0].personalNote);
                        setPersNoteEdit(persNoteJson[0].personalNote);
                    } catch (e) {
                        setPersNote('');
                        setPersNoteEdit('');
                    }
                }
            }
        }

        fetchData();

        const handleClick = (event) => {
            if (!event.target.matches('.optionsbtn')) {
                var dropdowns = document.getElementsByClassName("options");
                var i;
                for (i = 0; i < dropdowns.length; i++) {
                    var openDropdown = dropdowns[i];
                    if (openDropdown.classList.contains('show')) {
                        openDropdown.classList.remove('show');
                    }
                }
            }
        }

        window.addEventListener("click", handleClick);

        return() => {
            handleCancel();
            window.removeEventListener("click", handleClick);
        }
        
    }, [])

    useEffect(() => {
        if(typeof changerec.reference !== "undefined" && !fetched) {
            setFetched(true);
        }
    }, [changerec])

    useEffect(() => {
        nameRef.current = changerec.name;
    }, [changerec.name]);

    /*
    useEffect(() => {
        if(fetched) {
            let sortedIng = changerec.ingredients.toSorted((a, b) => a.sortNum - b.sortNum)
            setChangeRec({...changerec, ingredients: sortedIng})

        }
    }, [changerec.ingredients])*/

    function showOptionsDropdown() {
        document.getElementById("optionsDropdown").classList.toggle("show");
    }

    function handleDelete() {
        let url = 'https://api.poddies-kochbuch.de/recipes/' + params.recipe

        if(window.confirm("You are about to delete this recipe. Are you sure?")) {
            fetch(url, {
                method: "DELETE",
                headers: {
                    'Authorization': userContext.user.loginAns.token_type + " " + userContext.user.loginAns.access_token,
                },
            })
                .then(res => navigate("/alle-rezepte"));
        }
    }

    function handleEdit() {
        setDisabled(false);
        if(typeof recipe.name !== 'undefined') {
            setChangeRec(structuredClone(recipe));
        }
    }

    function validateInputs() {
        if(changerec.name === "") {
            toast.error("Please enter a name");
            return false;
        }
        else {
            return true;
        }
    }

    function handleCancel() {
        console.log(nameRef)
        if(nameRef.current === "") {
            handleDelete();
        }
        else {
            setDisabled(true);
            setChangeRec(structuredClone(recipe));
            setToBeDel([]);
        }
    }

    function handleFileUpload(event) {
        const fileUploaded = event.target.files[0];

        const formData = new FormData();

        formData.append(
            "file",
            fileUploaded
        )
        formData.append(
            "type",
            fileUploaded
        )

        let url = 'https://api.poddies-kochbuch.de/recipes/' + params.recipe + "/image";
        
        fetch(url, {
            method: "POST",
            headers: {
                'Authorization': userContext.user.loginAns.token_type + " " + userContext.user.loginAns.access_token
            },
            body: formData
        })
        .then(res => {
            if(res.status === 200) {
                toast.success("Image uploaded successfully")
            }
            else {
                toast.error("Image ulpoad failed")
        }})
    }

    function handleSave() {
        if(validateInputs()) {
            let url = 'https://api.poddies-kochbuch.de/recipes/' + params.recipe;
            

            fetch(url, {
                method: "PUT",
                headers: {
                    'Authorization': userContext.user.loginAns.token_type + " " + userContext.user.loginAns.access_token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({name: changerec.name,
                                    worksteps: changerec.worksteps,
                                    reference: changerec.reference,
                                    baseamount: changerec.baseamount,
                                    shortdesc: changerec.shortdesc,
                                    vegetary: changerec.vegetary,
                                    vegan: changerec.vegan,
            })})
            .then(res => {
                if(res.status === 200) {

                    changerec.ingredients.forEach(element => {
                        if(recipe.ingredients.some(e => e.idIngredient === element.idIngredient)) {
                            let newurl = 'https://api.poddies-kochbuch.de/recipes/' + params.recipe + "/ingredients/" + element.idIngredient;

                            fetch(newurl, {
                                method: "PUT",
                                headers: {
                                    'Authorization': userContext.user.loginAns.token_type + " " + userContext.user.loginAns.access_token,
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify(element
                            )})
                            .then(res => {
                                if(res.status !== 200) {
                                    toast.error("Failed to Update Ingredient");
                                }
                            })
                        }
                        else {
                            let newurl = 'https://api.poddies-kochbuch.de/recipes/' + params.recipe + "/ingredients";

                            fetch(newurl, {
                                method: "POST",
                                headers: {
                                    'Authorization': userContext.user.loginAns.token_type + " " + userContext.user.loginAns.access_token,
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify(element
                            )})
                            .then(res => {
                                if(res.status !== 200) {
                                    toast.error("Failed to Add Ingredient");
                                }
                            })
                        }
                    });

                    tobedel.forEach(element => {
                        let newurl = 'https://api.poddies-kochbuch.de/recipes/' + params.recipe + "/ingredients/" + element;
                        
                        fetch(newurl, {
                            method: "DELETE",
                            headers: {
                                'Authorization': userContext.user.loginAns.token_type + " " + userContext.user.loginAns.access_token,
                                'Content-Type': 'application/json'
                            }})
                        .then(res => {
                            if(res.status !== 200) {
                                toast.error("Failed to Delete Ingredient");
                            }
                        })
                    });

                    toast.success("Successfully saved the changes");
                    setDisabled(true);
                    setRecipe(structuredClone(changerec));
                    setToBeDel([]);
                }
                else {
                    toast.error("Failed to save the changes");
            }});
        }
    }

    function getOptions(selected){
        let options = []
        
        options = units.map((el, i) => {
            if(selected === el[Object.keys(el)[0]]) {
                return <option selected value={Object.keys(el)[0]} key={Object.keys(el)[0]}>{el[Object.keys(el)[0]]}</option>
            }
            else {
                return <option value={Object.keys(el)[0]} key={Object.keys(el)[0]}>{el[Object.keys(el)[0]]}</option>
            }
        })

        return options
    }

    function handleDragStart(e, item) {
        setDragItem(item);
        e.dataTransfer.setData("text/plain", "");
    }

    function handleDragEnd() {
        setDragItem({});
    }

    function handleDragOver(e) {
        e.preventDefault();
    }

    function handleDrop(e, item) {
        let sortedIng = changerec.ingredients.toSorted((a, b) => a.sortNum - b.sortNum);
        if (!draggItem) return;

        const currentIndex = sortedIng.indexOf(draggItem);
        const targetIndex = sortedIng.indexOf(item);

        if(currentIndex !== -1 && targetIndex !== -1) {
            sortedIng.splice(currentIndex, 1);
            sortedIng.splice(targetIndex, 0, draggItem);
            sortedIng.forEach((el, i) => {
                el.sortNum = i;
            });
            setChangeRec({...changerec, ingredients: sortedIng});
        }
    }

    function prepIng() {
        const sanitizeConf = {
            allowedTags: ["b", "i", "p"],
          };
        let ingredientlist = changerec.ingredients.map((el, i) => {
            if(el.unit === "Header" && disabled) {
                return (
                    <tr key={el.Ingredient}
                        draggable={(disabled) ? "false" : "true"}
                        onDragStart={(e) => handleDragStart(e, el)}
                        onDragEnd={handleDragEnd}
                        onDragOver={handleDragOver}
                        onDrop={(e) => handleDrop(e, el)}>
                        <td colSpan={3} className="IngHeader"><h4>{el.name}</h4></td>
                    </tr>
                )
            }
            else {
                return (
                    <tr key={el.idIngredient} 
                        draggable={(disabled) ? "false" : "true"}
                        onDragStart={(e) => handleDragStart(e, el)}
                        onDragEnd={handleDragEnd}
                        onDragOver={handleDragOver}
                        onDrop={(e) => handleDrop(e, el)} >
                        <td>   
                            <input disabled={disabled} pattern="/d" className="numberInputIng" type="number" value={el.amount} onChange={e => setChangeRec({ ...changerec,
                                    ingredients: [
                                    ...changerec.ingredients.slice(0,i),
                                    {
                                        ...changerec.ingredients[i],
                                        amount: sanitizeHtml(e.target.value, sanitizeConf),
                                    },
                                    ...changerec.ingredients.slice(i+1)
                                ]})}>

                            </input>
                        </td>
                        <td>
                            <select disabled={disabled} className="unitInputIng" onChange={e => setChangeRec({ ...changerec,
                                    ingredients: [
                                    ...changerec.ingredients.slice(0,i),
                                    {
                                        ...changerec.ingredients[i],
                                        unit: sanitizeHtml(e.target.value, sanitizeConf),
                                    },
                                    ...changerec.ingredients.slice(i+1)
                                ]})}>
                                {getOptions(el.unit)}
                            </select>
                        </td>
                        <td>
                            <ContentEditable 
                                html={el.name}
                                onChange={e => setChangeRec({ ...changerec,
                                    ingredients: [
                                    ...changerec.ingredients.slice(0,i),
                                    {
                                        ...changerec.ingredients[i],
                                        name: sanitizeHtml(e.target.value, sanitizeConf),
                                    },
                                    ...changerec.ingredients.slice(i+1)
                                ]})}
                                disabled={disabled}
                                className='editable'
                                />
                        </td>
                        <td style={{display: (disabled) ? "none" : "initial"}}>
                            <button disabled={disabled} className="deleteBtn" onClick={e => {setChangeRec({ ...changerec,
                                    ingredients: [
                                        ...changerec.ingredients.slice(0,i),
                                        ...changerec.ingredients.slice(i+1)
                                          ]
                                    });
                                    setToBeDel([...tobedel, el.idIngredient]);
                            }}></button>
                        </td>
                    </tr>
                )
            }
        })
        

        if(!disabled) {
            ingredientlist.push(
                <tr>
                    <td></td>
                    <td></td>
                    <td>
                        <button className="addingbtn" onClick={(e) => {setChangeRec({...changerec,
                                ingredients: [
                                    ...changerec.ingredients,
                                    {
                                        name: "",
                                        amount: 0,
                                        unit: "Stk",
                                        sortNum: changerec.ingredients.length
                                    }
                                ]})
                        }}>+</button>
                    </td>
                    <td></td>
                </tr>
            )
        }
        

        return ingredientlist;
    }

    function handleAmountChange(number) {
        let newamount = parseInt(changerec.baseamount) + number;
        setChangeRec({...changerec, 
                        baseamount: newamount, 
                        ingredients: changerec.ingredients.map((element ,i) => {
                            element.amount = Math.round(((recipe.ingredients[i].amount / recipe.baseamount) * newamount) * 100) / 100;
                            return element;
        })});
            
    }

    function fnShowCat() {
        setShowCat(true);
    }

    function fnHideCat() {
        setShowCat(false);
    }

    function handlePNCancel() {
        setPndisabled(true);
        setPersNoteEdit(persNote);
    }

    function handlePNSave() {
        if(persNote === "" && persNoteEdit !== "") {
            let url2 = 'https://api.poddies-kochbuch.de/recipes/' + params.recipe + "/pers_note?pers_note=" + persNoteEdit;

            fetch(url2, {
                method: "POST",
                headers: {
                    'Authorization': userContext.user.loginAns.token_type + " " + userContext.user.loginAns.access_token,
                },
            })
                .then(res => {
                    if(res.status === 200) {
                        toast.success("Persönliche Notiz erfolgreich gespeichert");
                    }
                    else {
                        toast.error("Persönliche Notiz konnte nicht gespeichert werden");
                    }
                }
            );
        }
        else if(persNoteEdit === "" && persNote !== "") {
            let url2 = 'https://api.poddies-kochbuch.de/recipes/' + params.recipe + "/pers_note";

            fetch(url2, {
                method: "DELETE",
                headers: {
                    'Authorization': userContext.user.loginAns.token_type + " " + userContext.user.loginAns.access_token,
                },
            })
                .then(res => {
                    if(res.status === 200) {
                        toast.success("Persönliche Notiz erfolgreich gelöscht");
                    }
                    else {
                        toast.error("Persönliche Notiz konnte nicht gelöscht werden");
                    }
                }
            );
        }
        else if(persNoteEdit !== "" && persNote !== "") {
            let url2 = 'https://api.poddies-kochbuch.de/recipes/' + params.recipe + "/pers_note?pers_note=" + persNoteEdit;

            fetch(url2, {
                method: "PUT",
                headers: {
                    'Authorization': userContext.user.loginAns.token_type + " " + userContext.user.loginAns.access_token,
                },
            })
                .then(res => {
                    if(res.status === 200) {
                        toast.success("Persönliche Notiz erfolgreich gespeichert");
                    }
                    else {
                        toast.error("Persönliche Notiz konnte nicht gespeichert werden");
                    }
                }
            );
        }

        setPndisabled(true);
        setPersNote(persNoteEdit);
    }

    function doNothing() {

    }

    if(fetched) {
        let path = "https://api.poddies-kochbuch.de/recipes/" + params.recipe + "/image"
        let options = []
        let ingredientlist = []
        if(typeof changerec.ingredients !== 'undefined') {
            ingredientlist = prepIng()
        }
        const sanitizeConf = {
            allowedTags: ["b", "i", "p", "br"],
          };

        const sanitizeConfShortDesc = {
            allowedTags: ["b", "i", "p"]
          };

        if(userContext.user.isLoggedIn  && disabled) {
            options.push(<div className='optionsmenu'>
                            <button className='optionsbtn' onClick={() => showOptionsDropdown()}>
                            </button>
                            <div className='options' id='optionsDropdown'>
                                <button onClick={() => fnShowCat()}>Show Categories</button>
                                <button onClick={() => setPndisabled(false)}>Persönliche Notiz <br/> bearbeiten</button>
                                {userContext.user.user === recipe.creator ? <button onClick={() => handleEdit()}>Edit</button> : <></>}
                                {userContext.user.user === recipe.creator ? <button onClick={() => handleDelete()}>Delete</button> : <></>}
                            </div>
                        </div>)
        }
        else {
            options = []
        }
        

        return (
            <>
                <div className='recipepage'>
                    <div className='recheadcontent'>
                        <h1>
                            <ContentEditable 
                                html={changerec.name}
                                onChange={e => setChangeRec({...changerec, name: sanitizeHtml(e.target.value, sanitizeConfShortDesc)})}
                                disabled={disabled}
                                className='editable'
                                />
                        </h1>
                        <ContentEditable 
                            html={changerec.shortdesc}
                            onChange={e => setChangeRec({...changerec, shortdesc: sanitizeHtml(e.target.value, sanitizeConfShortDesc)})}
                            disabled={disabled}
                            className='editable'
                            />
                        <table>
                            <tbody>
                                <tr key="rowReferenz">
                                    <td>Referenz:</td>
                                    <td>
                                        <ContentEditable 
                                        html={changerec.reference.includes("https://") ? `<a target="_blank" href=${changerec.reference}>${changerec.reference}</a>` : changerec.reference}
                                        onChange={e => setChangeRec({...changerec, reference: sanitizeHtml(e.target.value, sanitizeConf)})}
                                        disabled={disabled}
                                        className='editable'
                                        />
                                    </td>
                                </tr>
                                <tr key="rowErsteller">
                                    <td>Ersteller:</td>
                                    <td>{changerec.creator}</td>
                                </tr>
                                <tr key="rowDatum">
                                    <td>Erstelldatum:</td>
                                    <td>{new Date(changerec.creationdate).toLocaleDateString()}</td>
                                </tr>
                                <tr key="rowVegetarisch">
                                    <td><label htmlFor="vegetarisch">Vegetarisch</label></td>
                                    <td>
                                    <input type="checkbox" id="vegetarisch" checked={changerec.vegetary} disabled={disabled}
                                        onChange={(e) => setChangeRec({...changerec, vegetary: e.target.checked})}
                                    ></input>
                                    </td>
                                </tr>
                                <tr key="rowVegan">
                                    <td><label htmlFor="vegan">Vegan</label></td>
                                    <td>
                                    <input type="checkbox" id="vegan" checked={changerec.vegan} disabled={disabled}
                                        onChange={(e) => setChangeRec({...changerec, vegan: e.target.checked})}
                                    ></input>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className='recimg'>
                        <img src={path} alt="" className="recipeimg" onClick={() => !disabled ? fileInput.current.click() : doNothing()}></img>
                        <input ref={fileInput} type="file" onChange={(e) => handleFileUpload(e)} style={{display: 'none'}}/>
                    </div>
                    <div className='recing'>
                        <h2>Zutaten:</h2>
                        <div className='portionpicker'>
                            <button className='amountbtn sub' onClick={() => disabled ? handleAmountChange(-1) : doNothing()}>-</button>
                            <input disabled={disabled} className='portions' type="number" pattern="/d" value={changerec.baseamount} 
                                onChange={e => setChangeRec({...changerec, 
                                                                baseamount: e.target.value})}>
                            </input>
                            <button className='amountbtn add' onClick={() => disabled ? handleAmountChange(1) : doNothing()}>+</button>
                        </div>
                        <table className='zutatentable'>
                            <tbody> 
                                {ingredientlist}
                            </tbody>
                        </table>
                    </div>
                    <div className='recbody'>
                        <div className='recbodyhead' key="recbodyhead">
                            <h2>Zubereitung:</h2>
                            {options}
                        </div>
                        <ContentEditable
                            html={changerec.worksteps}
                            onChange={e => setChangeRec({...changerec, worksteps: sanitizeHtml(e.target.value, sanitizeConf)})}
                            disabled={disabled}
                            className='editable zubereitung'
                            />
                        <div className='persNote' style={{visibility: ((pndisabled && (persNote === "")) || !disabled) ? "hidden" : "initial"}} key="persNote">
                            <br/>
                            <br/>
                            <h2>Persönliche Notiz:</h2>
                            <ContentEditable
                                html={persNoteEdit}
                                onChange={e => setPersNoteEdit(sanitizeHtml(e.target.value, sanitizeConf))}
                                disabled={pndisabled}
                                className='editable zubereitung'
                                />
                            <div className='receditfooter' style={{visibility: (pndisabled) ? "hidden" : "initial"}}>
                                <button className='receditbtn receditcancelbtn' onClick={() => handlePNCancel()}>Cancel</button>
                                <button className='receditbtn receditsavebtn'onClick={() => handlePNSave()}>Save</button>
                            </div>
                        </div>
                    </div>
                    <div className='receditfooter' style={{visibility: (disabled) ? "hidden" : "initial"}}>
                        <button className='receditbtn receditcancelbtn' onClick={() => handleCancel()}>Cancel</button>
                        <button className='receditbtn receditsavebtn'onClick={() => handleSave()}>Save</button>
                    </div>
                    {userContext.user.isLoggedIn ? <CatWidget visible={showCat} hideCat={fnHideCat} rec={recipe.idRecipe}/> : null}
                </div>
            </>
        )
    }
    else {
        return <div className='loader'></div>
    }
}











/*
class RecipePage extends React.Component {
    static contextType = UserContext

    constructor(props) {
        super(props)
        this.state = {recipe: {},
                      changerec: {},
                      fetched: false,
                      disabled: true,
                      units: [],
                      tobedel: [],
                      showCat: false,
                      persNote: "",
                      persNoteEdit: "",
                      pndisabled: true}

        this.fileInput = createRef();

        this.hideCat = this.hideCat.bind(this);

    }

    componentDidMount() {
        const userData = this.context.user
        let url = 'https://api.poddies-kochbuch.de/recipes/' + this.props.params.recipe

        if(!this.state.fetched) {
            fetch(url)
                .then(res => res.json())
                .then(json => {
                    this.setState({recipe: json[0]})
                    this.setState({changerec: structuredClone(json[0])}, () => {
                        this.setState({fetched: true})
                    });
                    if(json[0].name === "") {
                        this.handleEdit();
                    }
                });
        }

        let url2 = 'https://api.poddies-kochbuch.de/units'

        fetch(url2)
            .then(res => res.json())
            .then(json => {
                this.setState({units: json})
            }
        );

        if(userData.isLoggedIn) {
            let url2 = 'https://api.poddies-kochbuch.de/recipes/' + this.props.params.recipe + "/pers_note";

            fetch(url2, {
                headers: {
                    'Authorization': userData.loginAns.token_type + " " + userData.loginAns.access_token,
                },
            })
                .then(res => {
                    if(res.status === 200) {
                        res.json()
                        .then(json => {
                            try{
                                this.setState({persNote: json[0].personalNote})
                                this.setState({persNoteEdit: json[0].personalNote})
                            }
                            catch(e) {
                                this.setState({persNote: ""})
                                this.setState({persNoteEdit: ""})
                            }
                        })
                    }
                    else {
                        return
                    }
                });
        }

        window.onclick = function(event) {
            if (!event.target.matches('.optionsbtn')) {
                var dropdowns = document.getElementsByClassName("options");
                var i;
                for (i = 0; i < dropdowns.length; i++) {
                    var openDropdown = dropdowns[i];
                    if (openDropdown.classList.contains('show')) {
                        openDropdown.classList.remove('show');
                    }
                }
            }
        }

    }

    componentWillUnmount() {
        this.handleCancel();
    }

    showOptionsDropdown() {
        document.getElementById("optionsDropdown").classList.toggle("show");
    }

    handleDelete() {
        let url = 'https://api.poddies-kochbuch.de/recipes/' + this.props.params.recipe
        const userData = this.context.user

        if(window.confirm("You are about to delete this recipe. Are you sure?")) {
            fetch(url, {
                method: "DELETE",
                headers: {
                    'Authorization': userData.loginAns.token_type + " " + userData.loginAns.access_token,
                },
            })
                .then(res => this.props.navigate("/alle-rezepte"));
        }
    }

    handleEdit() {
        this.setState({disabled: false});
        if(typeof this.state.recipe.name !== 'undefined') {
            this.setState({changerec: structuredClone(this.state.recipe)});
        }
    }

    handleSave() {
        const userData = this.context.user

        if(this.validateInputs()) {
            let url = 'https://api.poddies-kochbuch.de/recipes/' + this.props.params.recipe;
            

            fetch(url, {
                method: "PUT",
                headers: {
                    'Authorization': userData.loginAns.token_type + " " + userData.loginAns.access_token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({name: this.state.changerec.name,
                                    worksteps: this.state.changerec.worksteps,
                                    reference: this.state.changerec.reference,
                                    baseamount: this.state.changerec.baseamount,
                                    shortdesc: this.state.changerec.shortdesc,
                                    vegetary: this.state.changerec.vegetary,
                                    vegan: this.state.changerec.vegan,
            })})
            .then(res => {
                if(res.status === 200) {

                    this.state.changerec.ingredients.forEach(element => {
                        if(this.state.recipe.ingredients.some(e => e.idIngredient === element.idIngredient)) {
                            let newurl = 'https://api.poddies-kochbuch.de/recipes/' + this.props.params.recipe + "/ingredients/" + element.idIngredient;

                            fetch(newurl, {
                                method: "PUT",
                                headers: {
                                    'Authorization': userData.loginAns.token_type + " " + userData.loginAns.access_token,
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify(element
                            )})
                            .then(res => {
                                if(res.status !== 200) {
                                    toast.error("Failed to Update Ingredient");
                                }
                            })
                        }
                        else {
                            let newurl = 'https://api.poddies-kochbuch.de/recipes/' + this.props.params.recipe + "/ingredients";

                            fetch(newurl, {
                                method: "POST",
                                headers: {
                                    'Authorization': userData.loginAns.token_type + " " + userData.loginAns.access_token,
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify(element
                            )})
                            .then(res => {
                                if(res.status !== 200) {
                                    toast.error("Failed to Add Ingredient");
                                }
                            })
                        }
                    });

                    this.state.tobedel.forEach(element => {
                        let newurl = 'https://api.poddies-kochbuch.de/recipes/' + this.props.params.recipe + "/ingredients/" + element;
                        
                        fetch(newurl, {
                            method: "DELETE",
                            headers: {
                                'Authorization': userData.loginAns.token_type + " " + userData.loginAns.access_token,
                                'Content-Type': 'application/json'
                            }})
                        .then(res => {
                            if(res.status !== 200) {
                                toast.error("Failed to Delete Ingredient");
                            }
                        })
                    });

                    toast.success("Successfully saved the changes");
                    this.setState({disabled: true});
                    this.setState({recipe: structuredClone(this.state.changerec)});
                    this.setState({tobedel: []});
                }
                else {
                    toast.error("Failed to save the changes");
            }});
        }
    }

    validateInputs() {
        if(this.state.changerec.name === "") {
            toast.error("Please enter a name");
            return false;
        }
        else {
            return true;
        }
    }

    handleCancel() {
        if(this.state.changerec.name === "") {
            this.handleDelete();
        }
        else {
            this.setState({disabled: true});
            this.setState({changerec: structuredClone(this.state.recipe)});
            this.setState({tobedel: []});
        }
    }

    handleFileUpload(event) {
        const userData = this.context.user
        const fileUploaded = event.target.files[0];

        const formData = new FormData();

        formData.append(
            "file",
            fileUploaded
        )
        formData.append(
            "type",
            fileUploaded
        )

        let url = 'https://api.poddies-kochbuch.de/recipes/' + this.props.params.recipe + "/image";
        
        fetch(url, {
            method: "POST",
            headers: {
                'Authorization': userData.loginAns.token_type + " " + userData.loginAns.access_token
            },
            body: formData
        })
        .then(res => {
            if(res.status === 200) {
                toast.success("Image uploaded successfully")
            }
            else {
                toast.error("Image ulpoad failed")
        }})
    }

    getOptions(selected){
        let options = []
        
        options = this.state.units.map((el, i) => {
            if(selected === el[Object.keys(el)[0]]) {
                return <option selected value={Object.keys(el)[0]} key={Object.keys(el)[0]}>{el[Object.keys(el)[0]]}</option>
            }
            else {
                return <option value={Object.keys(el)[0]} key={Object.keys(el)[0]}>{el[Object.keys(el)[0]]}</option>
            }
        })

        return options
    }

    prepIng() {
        const sanitizeConf = {
            allowedTags: ["b", "i", "p"],
          };

        let ingredientlist = this.state.changerec.ingredients.map((el, i) => (
            <tr key={el.idIngredient}>
                <td>   
                    <input disabled={this.state.disabled} pattern="/d" className="numberInputIng" type="number" value={el.amount} onChange={e => this.setState({changerec: { ...this.state.changerec,
                            ingredients: [
                            ...this.state.changerec.ingredients.slice(0,i),
                            {
                                ...this.state.changerec.ingredients[i],
                                amount: sanitizeHtml(e.target.value, sanitizeConf),
                            },
                            ...this.state.changerec.ingredients.slice(i+1)
                        ]}})}>

                    </input>
                </td>
                <td>
                    <select disabled={this.state.disabled} className="unitInputIng" onChange={e => this.setState({changerec: { ...this.state.changerec,
                            ingredients: [
                            ...this.state.changerec.ingredients.slice(0,i),
                            {
                                ...this.state.changerec.ingredients[i],
                                unit: sanitizeHtml(e.target.value, sanitizeConf),
                            },
                            ...this.state.changerec.ingredients.slice(i+1)
                        ]}})}>
                        {this.getOptions(el.unit)}
                    </select>
                </td>
                <td>
                    <ContentEditable 
                        html={el.name}
                        onChange={e => this.setState({changerec: { ...this.state.changerec,
                            ingredients: [
                            ...this.state.changerec.ingredients.slice(0,i),
                            {
                                ...this.state.changerec.ingredients[i],
                                name: sanitizeHtml(e.target.value, sanitizeConf),
                            },
                            ...this.state.changerec.ingredients.slice(i+1)
                        ]}})}
                        disabled={this.state.disabled}
                        className='editable'
                        />
                </td>
                <td>
                    <button disabled={this.state.disabled} className="deleteBtn" onClick={e => {this.setState({changerec: { ...this.state.changerec,
                            ingredients: [
                            ...this.state.changerec.ingredients.slice(0,i),
                            ...this.state.changerec.ingredients.slice(i+1)
                            ]}})
                            this.setState({tobedel: [...this.state.tobedel, el.idIngredient]})
                        }}></button>
                </td>
            </tr>
        ))

        if(!this.state.disabled) {
            ingredientlist.push(
                <tr>
                    <td></td>
                    <td></td>
                    <td>
                        <button className="addingbtn" onClick={(e) => {this.setState({changerec: {...this.state.changerec,
                                ingredients: [
                                    ...this.state.changerec.ingredients,
                                    {
                                        name: "",
                                        amount: 0,
                                        unit: "Stk"
                                    }
                                ]}})
                        }}>+</button>
                    </td>
                </tr>
            )
        }
        

        return ingredientlist;
    }

    handleAmountChange(number) {
        let newamount = parseInt(this.state.changerec.baseamount) + number;
        this.setState({changerec: {...this.state.changerec, 
                                    baseamount: newamount, 
                                    ingredients: this.state.changerec.ingredients.map((element ,i) => {
                                        element.amount = Math.round(((this.state.recipe.ingredients[i].amount / this.state.recipe.baseamount) * newamount) * 100) / 100;
                                        return element;
        })}});
            
    }

    showCat() {
        this.setState({showCat: true});
    }

    hideCat() {
        this.setState({showCat: false});
    }

    handlePNCancel() {
        this.setState({
            pndisabled: true,
            persNoteEdit: this.state.persNote
        });
    }

    handlePNSave() {
        const userData = this.context.user
        if(this.state.persNote === "" && this.state.persNoteEdit !== "") {
            let url2 = 'https://api.poddies-kochbuch.de/recipes/' + this.props.params.recipe + "/pers_note?pers_note=" + this.state.persNoteEdit;

            fetch(url2, {
                method: "POST",
                headers: {
                    'Authorization': userData.loginAns.token_type + " " + userData.loginAns.access_token,
                },
            })
                .then(res => {
                    if(res.status === 200) {
                        toast.success("Persönliche Notiz erfolgreich gespeichert");
                    }
                    else {
                        toast.error("Persönliche Notiz konnte nicht gespeichert werden");
                    }
                }
            );
        }
        else if(this.state.persNoteEdit === "" && this.state.persNote !== "") {
            let url2 = 'https://api.poddies-kochbuch.de/recipes/' + this.props.params.recipe + "/pers_note";

            fetch(url2, {
                method: "DELETE",
                headers: {
                    'Authorization': userData.loginAns.token_type + " " + userData.loginAns.access_token,
                },
            })
                .then(res => {
                    if(res.status === 200) {
                        toast.success("Persönliche Notiz erfolgreich gelöscht");
                    }
                    else {
                        toast.error("Persönliche Notiz konnte nicht gelöscht werden");
                    }
                }
            );
        }
        else if(this.state.persNoteEdit !== "" && this.state.persNote !== "") {
            let url2 = 'https://api.poddies-kochbuch.de/recipes/' + this.props.params.recipe + "/pers_note?pers_note=" + this.state.persNoteEdit;

            fetch(url2, {
                method: "PUT",
                headers: {
                    'Authorization': userData.loginAns.token_type + " " + userData.loginAns.access_token,
                },
            })
                .then(res => {
                    if(res.status === 200) {
                        toast.success("Persönliche Notiz erfolgreich gespeichert");
                    }
                    else {
                        toast.error("Persönliche Notiz konnte nicht gespeichert werden");
                    }
                }
            );
        }

        this.setState({
            pndisabled: true,
            persNote: this.state.persNoteEdit
        });
    }

    doNothing() {

    }


    render() {
        if(this.state.fetched) {
            let path = "https://api.poddies-kochbuch.de/recipes/" + this.props.params.recipe + "/image"
            let options = []
            let ingredientlist = []
            if(typeof this.state.changerec.ingredients !== 'undefined') {
                ingredientlist = this.prepIng()
            }

            const userData = this.context.user;
            const sanitizeConf = {
                allowedTags: ["b", "i", "p", "br"],
              };

            const sanitizeConfShortDesc = {
                allowedTags: ["b", "i", "p"]
              };

            if(userData.isLoggedIn  && this.state.disabled) {
                options.push(<div className='optionsmenu'>
                                <button className='optionsbtn' onClick={() => this.showOptionsDropdown()}>
                                </button>
                                <div className='options' id='optionsDropdown'>
                                    <button onClick={() => this.showCat()}>Show Categories</button>
                                    <button onClick={() => this.setState({pndisabled: false})}>Persönliche Notiz <br/> bearbeiten</button>
                                    {userData.user === this.state.recipe.creator ? <button onClick={() => this.handleEdit()}>Edit</button> : <></>}
                                    {userData.user === this.state.recipe.creator ? <button onClick={() => this.handleDelete()}>Delete</button> : <></>}
                                </div>
                            </div>)
            }
            else {
                options = []
            }
            

            return (
                <>
                    <div className='recipepage'>
                        <div className='recheadcontent'>
                            <h1>
                                <ContentEditable 
                                    html={this.state.changerec.name}
                                    onChange={e => this.setState({changerec: {...this.state.changerec, name: sanitizeHtml(e.target.value, sanitizeConfShortDesc)}})}
                                    disabled={this.state.disabled}
                                    className='editable'
                                    />
                            </h1>
                            <ContentEditable 
                                html={this.state.changerec.shortdesc}
                                onChange={e => this.setState({changerec: {...this.state.changerec, shortdesc: sanitizeHtml(e.target.value, sanitizeConfShortDesc)}})}
                                disabled={this.state.disabled}
                                className='editable'
                                />
                            <table>
                                <tbody>
                                    <tr key="rowReferenz">
                                        <td>Referenz:</td>
                                        <td>
                                            <ContentEditable 
                                            html={this.state.changerec.reference.includes("https://") ? `<a target="_blank" href=${this.state.changerec.reference}>${this.state.changerec.reference}</a>` : this.state.changerec.reference}
                                            onChange={e => this.setState({changerec: {...this.state.changerec, reference: sanitizeHtml(e.target.value, sanitizeConf)}})}
                                            disabled={this.state.disabled}
                                            className='editable'
                                            />
                                        </td>
                                    </tr>
                                    <tr key="rowErsteller">
                                        <td>Ersteller:</td>
                                        <td>{this.state.changerec.creator}</td>
                                    </tr>
                                    <tr key="rowDatum">
                                        <td>Erstelldatum:</td>
                                        <td>{new Date(this.state.changerec.creationdate).toLocaleDateString()}</td>
                                    </tr>
                                    <tr key="rowVegetarisch">
                                        <td><label htmlFor="vegetarisch">Vegetarisch</label></td>
                                        <td>
                                        <input type="checkbox" id="vegetarisch" checked={this.state.changerec.vegetary} disabled={this.state.disabled}
                                            onChange={(e) => this.setState({changerec: {...this.state.changerec, vegetary: e.target.checked}})}
                                        ></input>
                                        </td>
                                    </tr>
                                    <tr key="rowVegan">
                                        <td><label htmlFor="vegan">Vegan</label></td>
                                        <td>
                                        <input type="checkbox" id="vegan" checked={this.state.changerec.vegan} disabled={this.state.disabled}
                                            onChange={(e) => this.setState({changerec: {...this.state.changerec, vegan: e.target.checked}})}
                                        ></input>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className='recimg'>
                            <img src={path} alt="" className="recipeimg" onClick={() => !this.state.disabled ? this.fileInput.current.click() : this.doNothing()}></img>
                            <input ref={this.fileInput} type="file" onChange={(e) => this.handleFileUpload(e)} style={{display: 'none'}}/>
                        </div>
                        <div className='recing'>
                            <h2>Zutaten:</h2>
                            <div className='portionpicker'>
                                <button className='amountbtn sub' onClick={() => this.state.disabled ? this.handleAmountChange(-1) : this.doNothing()}>-</button>
                                <input disabled={this.state.disabled} className='portions' type="number" pattern="/d" value={this.state.changerec.baseamount} 
                                    onChange={e => this.setState({changerec: {...this.state.changerec, 
                                                                            baseamount: e.target.value}})}>
                                </input>
                                <button className='amountbtn add' onClick={() => this.state.disabled ? this.handleAmountChange(1) : this.doNothing()}>+</button>
                            </div>
                            <table className='zutatentable'>
                                <tbody> 
                                    {ingredientlist}
                                </tbody>
                            </table>
                        </div>
                        <div className='recbody'>
                            <div className='recbodyhead' key="recbodyhead">
                                <h2>Zubereitung:</h2>
                                {options}
                            </div>
                            <ContentEditable
                                html={this.state.changerec.worksteps}
                                onChange={e => this.setState({changerec: {...this.state.changerec, worksteps: sanitizeHtml(e.target.value, sanitizeConf)}})}
                                disabled={this.state.disabled}
                                className='editable zubereitung'
                                />
                            <div className='persNote' style={{visibility: ((this.state.pndisabled && (this.state.persNote === "")) || !this.state.disabled) ? "hidden" : "initial"}} key="persNote">
                                <br/>
                                <br/>
                                <h2>Persönliche Notiz:</h2>
                                <ContentEditable
                                    html={this.state.persNoteEdit}
                                    onChange={e => this.setState({persNoteEdit: sanitizeHtml(e.target.value, sanitizeConf)})}
                                    disabled={this.state.pndisabled}
                                    className='editable zubereitung'
                                    />
                                <div className='receditfooter' style={{visibility: (this.state.pndisabled) ? "hidden" : "initial"}}>
                                    <button className='receditbtn receditcancelbtn' onClick={() => this.handlePNCancel()}>Cancel</button>
                                    <button className='receditbtn receditsavebtn'onClick={() => this.handlePNSave()}>Save</button>
                                </div>
                            </div>
                        </div>
                        <div className='receditfooter' style={{visibility: (this.state.disabled) ? "hidden" : "initial"}}>
                            <button className='receditbtn receditcancelbtn' onClick={() => this.handleCancel()}>Cancel</button>
                            <button className='receditbtn receditsavebtn'onClick={() => this.handleSave()}>Save</button>
                        </div>
                        {userData.isLoggedIn ? <CatWidget visible={this.state.showCat} hideCat={this.hideCat} rec={this.state.recipe.idRecipe}/> : null}
                    </div>
                </>
            )
        }
        else {
            return <div className='loader'></div>
        }
    }
}


function withHooks(Component) {
    return props => <RecipePage navigate={useNavigate()} params={useParams()} {...props} />;
}

export default withHooks(RecipePage)
*/