import React from "react";
import { NavLink } from "react-router-dom";
import "./footer.scss";

export default function Footer() {

    return(
        <div className="footer">
            <div className="links">
                <div className="block1">
                    <NavLink to="/aboutus">Über uns</NavLink>
                    <NavLink to="/impressum">Impressum</NavLink>
                </div>
            </div>
        </div>
    )
}




/*
class Footer extends React.Component {

    constructor(props, context) {
        super(props);
    }

    render() {

        return(
            <div className="footer">
                <div className="links">
                    <div className="block1">
                        <NavLink to="/aboutus">Über uns</NavLink>
                        <NavLink to="/impressum">Impressum</NavLink>
                    </div>
                </div>
            </div>
        )
    }

}

export default Footer;
*/