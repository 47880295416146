import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import './header.scss';
import UserContext from "../../UserContext";

export default function Header(props) {

    const userContext = useContext(UserContext);

    function handleLogout(e) {
        props.handleLogout();
    }

    let buttons =<> <NavLink to="/alle-rezepte"><button className="tbbtn">Alle Rezepte</button></NavLink>
                        <NavLink to="/zutatensuche"><button className="tbbtn">Zutaten Suche</button></NavLink>
                        {userContext.user.isLoggedIn ?  <>
                                                    <NavLink to="/kategorien"><button className="tbbtn">Kategorien</button></NavLink>
                                                    <div className="accountbtn dropdown"> 
                                                        <button className="tbbtn">{userContext.user.user}</button>
                                                        <div className="dropdown-content">
                                                            <NavLink to="/user"><button className="tbbtn">Settings</button></NavLink>
                                                            <button className="tbbtn" onClick={handleLogout}>Logout</button>
                                                        </div>
                                                    </div> 
                                                </>   
                                                :  <NavLink to="/login" className="accountbtn"><button className="tbbtn">Login</button></NavLink> }
                        </>;
                        
    return (
        <div className="header">
            <div className="headline">
                <a href="/"><h1>Poddies Kochbuch</h1></a>
            </div>
            <div className="toolbar">
                {buttons}
            </div>
        </div>
    )
}






/*
class Header extends React.Component{
    static contextType = UserContext

    constructor(props) {
        super(props)
        this.handleLogout = this.handleLogout.bind(this);

    }

    handleLogout(e) {
        this.props.handleLogout();
    }

    render() {
        const userData = this.context.user


        let buttons =<> <NavLink to="/alle-rezepte"><button className="tbbtn">Alle Rezepte</button></NavLink>
                        <NavLink to="/zutatensuche"><button className="tbbtn">Zutaten Suche</button></NavLink>
                        {userData.isLoggedIn ?  <>
                                                    <NavLink to="/kategorien"><button className="tbbtn">Kategorien</button></NavLink>
                                                    <div className="accountbtn dropdown"> 
                                                        <button className="tbbtn">{userData.user}</button>
                                                        <div className="dropdown-content">
                                                            <NavLink to="/user"><button className="tbbtn">Settings</button></NavLink>
                                                            <button className="tbbtn" onClick={this.handleLogout}>Logout</button>
                                                        </div>
                                                    </div> 
                                                </>   
                                                :  <NavLink to="/login" className="accountbtn"><button className="tbbtn">Login</button></NavLink> }
                        </>;
                        
        return (
            <div className="header">
                <div className="headline">
                    <a href="/"><h1>Poddies Kochbuch</h1></a>
                </div>
                <div className="toolbar">
                    {buttons}
                </div>
            </div>
        )
    }
}

export default Header;
*/