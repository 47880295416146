import UserContext from '../../UserContext';
import React, { useContext, useEffect, useState } from 'react';
import './usersite.scss';
import { toast } from 'react-hot-toast';

export default function UserSite(props) {

    const userContext = useContext(UserContext);

    const [fetched, setFetched] = useState(false);
    const [userCreds, setUserCreds] = useState({});
    const [theme, setTheme] = useState({});


    useEffect(() => {
        let url = 'https://api.poddies-kochbuch.de/usercreds';

        if(!fetched) {
            fetch(url, {
                method: "GET",
                headers: {
                    'Authorization': userContext.user.loginAns.token_type + " " + userContext.user.loginAns.access_token,
                },
            })
            .then(res => res.json())
            .then(json => {
                setFetched(true);
                setUserCreds(json);
            });

            url = 'https://api.poddies-kochbuch.de/theme';
            fetch(url, {
                method: "GET",
                headers: {
                    'Authorization': userContext.user.loginAns.token_type + " " + userContext.user.loginAns.access_token,
                },
            })
            .then(res => res.json())
            .then(json => {
                setTheme(json);
            });
        }
    }, [fetched, userContext.user.loginAns.access_token, userContext.user.loginAns.token_type])

    function handleSave(e) {
        e.preventDefault();
        let url = 'https://api.poddies-kochbuch.de/usercreds';
        localStorage.setItem("themeMode", userCreds.theme);

        fetch(url, {
            method: "POST",
            headers: {
                'Authorization': userContext.user.loginAns.token_type + " " + userContext.user.loginAns.access_token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(userCreds),
        })
        .then(res => {
            if(res.status === 200) {
                toast.success("Updates User Credentials successfully")
            }
        });

        if(userCreds.theme === 'custom') {
            let url = 'https://api.poddies-kochbuch.de/theme';
            localStorage.setItem("theme", JSON.stringify(theme))
          

            fetch(url, {
                method: "POST",
                headers: {
                    'Authorization': userContext.user.loginAns.token_type + " " + userContext.user.loginAns.access_token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(theme),
            })
            .then(res => {
                if(res.status === 200) {
                    toast.success("Your theme has been updated successfully.")
                }
            });
        }

        props.changeTheme();
    }

    return (
        <>
            <form name="usercreds" onSubmit={(e) => handleSave(e)}>
                <table className='usercredstab'>
                    <tbody>
                        <tr>
                            <th colSpan={2}>
                                User Data
                            </th>
                        </tr>
                        <tr>
                            <td>
                                <label htmlFor="email">E-Mail:</label>
                            </td>
                            <td>
                                <input type="email" name="email" id="email" value={userCreds.email} onChange={e => setUserCreds({...userCreds, email: e.target.value})} required></input>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label htmlFor='theme'>Theme:</label>
                            </td>
                            <td>
                                <select name="theme" id="theme" value={userCreds.theme} onChange={e => setUserCreds({...userCreds, theme: e.target.value})}>
                                    <option value="light">Light</option>
                                    <option vlaue="dark">Dark</option>
                                    <option value="custom">Custom</option>
                                </select>
                            </td>
                        </tr>
                    </tbody>
                    <tbody style={userCreds.theme === 'custom' ? {display: 'table-row-group'} : {display: 'none'}}>
                        <tr>
                            <td colSpan={2}><br/></td>
                        </tr>
                        <tr>
                            <th colSpan={2}>
                                Theme Colors
                            </th>
                        </tr>
                        <tr>
                            <td>
                                <label htmlFor="mainbg">1. Background:</label>
                            </td>
                            <td>
                                <input type="color" name="mainbg" id="mainbg" value={theme.mainbg} onChange={e => setTheme({...theme, mainbg: e.target.value})}></input>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label htmlFor="secbg">2. Background:</label>
                            </td>
                            <td>
                                <input type="color" name="secbg" id="secbg" value={theme.secondbg} onChange={e => setTheme({...theme, secondbg: e.target.value})}></input>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label htmlFor="accent">Accent:</label>
                            </td>
                            <td>
                                <input type="color" name="accent" id="accent" value={theme.accent} onChange={e => setTheme({...theme, accent: e.target.value})}></input>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label htmlFor="accentdark">Dark Accent:</label>
                            </td>
                            <td>
                                <input type="color" name="accentdark" id="accentdark" value={theme.accentdark} onChange={e => setTheme({...theme, accentdark: e.target.value})}></input>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label htmlFor="text">Text:</label>
                            </td>
                            <td>
                                <input type="color" name="text" id="text" value={theme.text} onChange={e => setTheme({...theme, text: e.target.value})}></input>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label htmlFor="textaccent">Text on Accent:</label>
                            </td>
                            <td>
                                <input type="color" name="textaccent" id="textaccent" value={theme.textaccent} onChange={e => setTheme({...theme, textaccent: e.target.value})}></input>
                            </td>
                        </tr>
                    </tbody>
                    <tbody>
                        <tr>
                            <td>

                            </td>
                            <td>
                                <input className='savebutton' type="submit" value="Save Changes" />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </>
    )

}



/*
class UserSite extends React.Component{
    static contextType = UserContext

    constructor(props) {
        super(props);
        this.state = {
            fetched: false,
            userCreds: {},
            theme: {}
        }
    }

    componentDidMount() {
        const userData = this.context.user;
        let url = 'https://api.poddies-kochbuch.de/usercreds';

        if(!this.state.fetched) {
            fetch(url, {
                method: "GET",
                headers: {
                    'Authorization': userData.loginAns.token_type + " " + userData.loginAns.access_token,
                },
            })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    fetched: true,
                    userCreds: json
                });
            });

            url = 'https://api.poddies-kochbuch.de/theme';
            fetch(url, {
                method: "GET",
                headers: {
                    'Authorization': userData.loginAns.token_type + " " + userData.loginAns.access_token,
                },
            })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    theme: json
                });
            });
        }
    }
    handleSave(e) {
        e.preventDefault();
        const userData = this.context.user;
        let url = 'https://api.poddies-kochbuch.de/usercreds';
        localStorage.setItem("themeMode", this.state.userCreds.theme);

        fetch(url, {
            method: "POST",
            headers: {
                'Authorization': userData.loginAns.token_type + " " + userData.loginAns.access_token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(this.state.userCreds
            ),
        })
        .then(res => {
            if(res.status === 200) {
                toast.success("Updates User Credentials successfully")
            }
        });

        if(this.state.userCreds.theme === 'custom') {
            let url = 'https://api.poddies-kochbuch.de/theme';
            localStorage.setItem("theme", JSON.stringify(this.state.theme))
          

            fetch(url, {
                method: "POST",
                headers: {
                    'Authorization': userData.loginAns.token_type + " " + userData.loginAns.access_token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(this.state.theme
                ),
            })
            .then(res => {
                if(res.status === 200) {
                    toast.success("Your theme has been updated successfully.")
                }
            });
        }

        this.props.changeTheme();
    }

    render() {
        return (
            <>
                <form name="usercreds" onSubmit={(e) => this.handleSave(e)}>
                    <table className='usercredstab'>
                        <tbody>
                            <tr>
                                <th colSpan={2}>
                                    User Data
                                </th>
                            </tr>
                            <tr>
                                <td>
                                    <label htmlFor="email">E-Mail:</label>
                                </td>
                                <td>
                                    <input type="email" name="email" id="email" value={this.state.userCreds.email} onChange={e => this.setState({userCreds: {...this.state.userCreds, email: e.target.value}})} required></input>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label htmlFor='theme'>Theme:</label>
                                </td>
                                <td>
                                    <select name="theme" id="theme" value={this.state.userCreds.theme} onChange={e => this.setState({userCreds: {...this.state.userCreds, theme: e.target.value}})}>
                                        <option value="light">Light</option>
                                        <option vlaue="dark">Dark</option>
                                        <option value="custom">Custom</option>
                                    </select>
                                </td>
                            </tr>
                        </tbody>
                        <tbody style={this.state.userCreds.theme === 'custom' ? {display: 'table-row-group'} : {display: 'none'}}>
                            <tr>
                                <td colSpan={2}><br/></td>
                            </tr>
                            <tr>
                                <th colSpan={2}>
                                    Theme Colors
                                </th>
                            </tr>
                            <tr>
                                <td>
                                    <label htmlFor="mainbg">1. Background:</label>
                                </td>
                                <td>
                                    <input type="color" name="mainbg" id="mainbg" value={this.state.theme.mainbg} onChange={e => this.setState({theme: {...this.state.theme, mainbg: e.target.value}})}></input>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label htmlFor="secbg">2. Background:</label>
                                </td>
                                <td>
                                    <input type="color" name="secbg" id="secbg" value={this.state.theme.secondbg} onChange={e => this.setState({theme: {...this.state.theme, secondbg: e.target.value}})}></input>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label htmlFor="accent">Accent:</label>
                                </td>
                                <td>
                                    <input type="color" name="accent" id="accent" value={this.state.theme.accent} onChange={e => this.setState({theme: {...this.state.theme, accent: e.target.value}})}></input>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label htmlFor="accentdark">Dark Accent:</label>
                                </td>
                                <td>
                                    <input type="color" name="accentdark" id="accentdark" value={this.state.theme.accentdark} onChange={e => this.setState({theme: {...this.state.theme, accentdark: e.target.value}})}></input>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label htmlFor="text">Text:</label>
                                </td>
                                <td>
                                    <input type="color" name="text" id="text" value={this.state.theme.text} onChange={e => this.setState({theme: {...this.state.theme, text: e.target.value}})}></input>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label htmlFor="textaccent">Text on Accent:</label>
                                </td>
                                <td>
                                    <input type="color" name="textaccent" id="textaccent" value={this.state.theme.textaccent} onChange={e => this.setState({theme: {...this.state.theme, textaccent: e.target.value}})}></input>
                                </td>
                            </tr>
                        </tbody>
                        <tbody>
                            <tr>
                                <td>

                                </td>
                                <td>
                                    <input className='savebutton' type="submit" value="Save Changes" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </form>
            </>
        )
    }
}

export default UserSite;
*/