import React from 'react';
import { NavLink } from 'react-router-dom';

export default function RecipeCard(props) {

    let path = "https://api.poddies-kochbuch.de/recipes/" + props.id + "/image"
        return(
            <NavLink className="recipecard" to={"/rezept/" + props.id}>
                <img src={path} alt="" className="recipeimage"></img>
                <p className="recipename">{props.name}</p>
                <p className="recshortdesc">{props.shortdesc}</p>
            </NavLink>
        );
}




/*
class RecipeCard extends React.Component{

    render() {
        let path = "https://api.poddies-kochbuch.de/recipes/" + this.props.id + "/image"
        return(
            <NavLink className="recipecard" to={"/rezept/" + this.props.id}>
                <img src={path} alt="" className="recipeimage"></img>
                <p className="recipename">{this.props.name}</p>
                <p className="recshortdesc">{this.props.shortdesc}</p>
            </NavLink>
        );
    }
}

export default RecipeCard;
*/