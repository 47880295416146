import React, { useState } from "react";

export default function AddCatWidget(props) {

    const [stateCatname, setStateCatname] = useState("");


    if(props.name !== null && stateCatname === "") {
        setStateCatname(props.name)
    }
    return(   
        <>
            <div className="addCatWidBack" style={{visibility: !props.visible ? "hidden" : "initial"}}>
                <div className="addCatWid">
                    <input value={stateCatname} onChange={e => setStateCatname(e.target.value)} placeholder="Categoryname..."></input>
                    <div className="buttonbar">
                        <button onClick={e => {setStateCatname("") 
                                                props.hide()}}>Cancel</button>
                        <button onClick={e => handleSave()}>Save</button>
                    </div>
                </div>
            </div>
        </>
    )

    function handleSave() {
        props.handleSave(stateCatname, props.catid)
        props.hide();
        setStateCatname("");
    }
}


/*
class AddCatWidget extends React.Component {
    static contextType = UserContext

    constructor(props) {
        super(props)
        this.state = {catname: ""}
    }

    handleSave() {
        this.props.handleSave(this.state.catname, this.props.catid)
        this.props.hide();
        this.setState({catname: ""});
        
    }

    render() {
        if(this.props.name !== null && this.state.catname === "") {
            this.setState({catname: this.props.name})
        }
        return (
            <>
                <div className="addCatWidBack" style={{visibility: !this.props.visible ? "hidden" : "initial"}}>
                    <div className="addCatWid">
                        <input value={this.state.catname} onChange={e => this.setState({catname: e.target.value})} placeholder="Categoryname..."></input>
                        <div className="buttonbar">
                            <button onClick={e => {this.setState({catname: ""}) 
                                                   this.props.hide()}}>Cancel</button>
                            <button onClick={e => this.handleSave()}>Save</button>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

function withHooks(Component) {
    return props => <AddCatWidget {...props} />;
}

export default withHooks(AddCatWidget);
*/