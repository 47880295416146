import './App.css';
import React from 'react';
import {BrowserRouter as Router, Route, Routes, Navigate} from "react-router-dom"
import Header from "./pages/sitebackbone/header"
import RecipeList from './pages/recipelist/recipelist';
import CategoryList from './pages/categorylist/categorylist';
import AboutUs from './pages/informationpages/aboutus';
import Login from './pages/loginpage/login';
import CatRecipeList from './pages/categorylist/catrecipelist';
import Recipepage from './pages/recipepage/recipepage';
import RegisterPage from "./pages/registerpage/registerpage"
import UserContext from './UserContext';
import UserSite from './pages/usersite/usersite';
import { Toaster } from 'react-hot-toast';
import Footer from './pages/sitebackbone/footer';
import Impressum from './pages/informationpages/impressum';
import IngredientSearch from './pages/ingredientsearch/ingredientsearch';

class App extends React.Component {
  static contextType = UserContext


  constructor(props, context) {
    super(props)
    this.handleLogin = this.handleLogin.bind(this)
    this.handleLogout = this.handleLogout.bind(this);
    this.changeTheme = this.changeTheme.bind(this);

    let isLoggedIn = JSON.parse(localStorage.getItem('isLoggedIn'));
    this.context = context
    
    this.context.user = {isLoggedIn: isLoggedIn,
      loginAns: JSON.parse(localStorage.getItem('loginData')),
      user: JSON.parse(localStorage.getItem('user'))}
  }

  componentDidMount() {
    const setUser = this.context.setUser
    let isLoggedIn = JSON.parse(localStorage.getItem('isLoggedIn'));
    if(isLoggedIn) {
      setUser({isLoggedIn: isLoggedIn,
              loginAns: JSON.parse(localStorage.getItem('loginData')),
              user: JSON.parse(localStorage.getItem('user'))
      });

      this.changeTheme();
      
      
    }
  }

  changeTheme() {
    let root = document.documentElement;
    let themeMode = "light";
    try {
      themeMode = localStorage.getItem('themeMode');
    }
    catch(e) {
      themeMode = "light";
    }

    let theme = {mainbg: "#FFFFFF",secondbg: "#90ee90",accent: "#008000", accentdark: "#006400", text: "#000000", textaccent: "#FFFFFF"};
    if(themeMode !== "light") {
      if(themeMode === "Dark") {
        theme = {mainbg: "#525252",secondbg: "#008000",accent: "#008000", accentdark: "#006400", text: "#FFFFFF", textaccent: "#FFFFFF"};
      }
      else if(themeMode === "custom") {
        theme = JSON.parse(localStorage.getItem('theme'));
      }

      
    }

    root.style.setProperty('--main-bg-color', theme.mainbg);
    root.style.setProperty('--second-bg-color', theme.secondbg);
    root.style.setProperty('--accent-color', theme.accent);
    root.style.setProperty('--dark-accent-color', theme.accentdark);
    root.style.setProperty('--text-color', theme.text);
    root.style.setProperty('--text-accent-color', theme.textaccent);

    
  }

  async handleLogin(username, password) {

    let data = new FormData();
    data.append('username', username);
    data.append('password', password);
    const setUser = this.context.setUser

    let res = await fetch('https://api.poddies-kochbuch.de/login', {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
            },
            body: data,
        });
      if(res.status === 200) {
        let json = await res.json()
        setUser({loginAns: json,
                      isLoggedIn: true,
                      user: username})
        localStorage.setItem('isLoggedIn', JSON.stringify(true))
        localStorage.setItem('loginData', JSON.stringify(json))
        localStorage.setItem('user', JSON.stringify(username))
        localStorage.setItem("themeMode", "light")
        let userData = json;

        let url = 'https://api.poddies-kochbuch.de/usercreds';
        
        await fetch(url, {
          method: "GET",
          headers: {
              'Authorization': userData.token_type + " " + userData.access_token,
          },
        })
        .then(res => res.json())
        .then(json => {
          if(json.theme === 'Dark') {

            localStorage.setItem("themeMode", "dark")
          } 
          else if(json.theme === 'custom') {
            let url = 'https://api.poddies-kochbuch.de/theme';
    
            fetch(url, {
              method: "GET",
              headers: {
                  'Authorization': userData.token_type + " " + userData.access_token,
              },
            })
            .then(res => res.json())
            .then(json => {
    
              localStorage.setItem("themeMode", "custom")
              localStorage.setItem("theme", JSON.stringify(json))
            });
          }
        });
      }
      return res.status;

  }

  handleLogout() {
    const setUser = this.context.setUser
    setUser({loginAns: "",
                  isLoggedIn: false,
                  user: ""})
    localStorage.setItem('isLoggedIn', JSON.stringify(false))
    localStorage.setItem('loginData', JSON.stringify({}))
    localStorage.setItem('user', JSON.stringify(""))
  }


  render() {
    const userData = this.context.user
    return (
      <>
        <Router>
          <div><Toaster/></div>
          <Header handleLogout={this.handleLogout}/>
          <div className='content'>
            <Routes>
              <Route path="/alle-rezepte" element={<RecipeList/>}/>
              <Route path="/rezept/:recipe" element={<Recipepage />}/>
              <Route path="/kategorien" element={!userData.isLoggedIn ? <Navigate to="/login"/> : <CategoryList/>}/>
              <Route path="/kategorien/:category" element={!userData.isLoggedIn ? <Navigate to="/login"/> : <CatRecipeList/>}/>
              <Route path="/login" element={<Login handleLogin={this.handleLogin}/>}/>
              <Route path="/register" element={<RegisterPage/>}/>
              <Route path="/user" element={!userData.isLoggedIn ? <Navigate to="/login"/> : <UserSite changeTheme={this.changeTheme}/>}/>
              <Route path="/impressum" element={<Impressum/>}/>
              <Route path="/aboutus" element={<AboutUs/>}/>
              <Route path="/zutatensuche" element={<IngredientSearch/>}/>
              <Route path="*" element={<Navigate to="/alle-rezepte"/>}/>
            </Routes>
          </div>
          <Footer />
        </Router>
      </>
    );
  }
}

export default App;
