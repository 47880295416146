/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import CategoryCard from './categorycard';
import './categorylist.scss'
import UserContext from '../../UserContext';
import AddCatWidget from './addcatwidget';
import { toast } from 'react-hot-toast';

export default function CategoryList(props) {

    const userContext = useContext(UserContext);

    const [list, setList] = useState([]);
    const [baselist, setBaselist] = useState([]);
    const [showwidget, setShowwidget] = useState(false);
    const [catwidgetname, setCatwidgetname] = useState(null);
    const [catwidgetid, setCatwidgetid] = useState(null);
    const [recipes, setRecipes] = useState([]);

    useEffect(() => {
        fetch('https://api.poddies-kochbuch.de/categories', {
            headers: {
                'Authorization': userContext.user.loginAns.token_type + " " + userContext.user.loginAns.access_token,
            },
        })
            .then(res => res.json())
            .then(json => {
                setList(json)
                setBaselist(json)
            });
    }, []);

    useEffect(() => {
        let recipes = list.map((el, i) => (
            <CategoryCard key={el.idCategory} id={el.idCategory} name={el.catname} count={el.count} firstrec={el.recipes[0]} handleDelete={handleDelete} handleEdit={handleEdit}/>
        ))

        setRecipes(recipes);
    }, [list])

    return(
        <>
            <h1>Hier sehen Sie alle Ihre Kategorien:</h1> 
            <div className='katlisttoolbar'>
                <button className='catlistbtn' onClick={e => showWidget()}>New +</button>
                <input className="searchbar" type="text" placeholder="Search..." onChange={(e) => handleSearch(e)}/>
            </div>
            <div className='categorylist'>
                {recipes}
            </div>
            <AddCatWidget visible={showwidget} hide={hideWidget} name={catwidgetname} catid={catwidgetid} handleSave={handleCatSave}/>
        </>
    );

    function showWidget(newCatwidgetname, newCatwidgetid) {
        setCatwidgetname(newCatwidgetname)
        setCatwidgetid(newCatwidgetid)
        setShowwidget(true)
        
    }

    function hideWidget() {
        setShowwidget(false)
        setCatwidgetname(null)
        setCatwidgetid(null)
    }

    function handleSearch(e) {
        if(e.target.value === "") {
            setList(baselist);
        }
        else {
            setList(baselist.filter(element => element.catname.toUpperCase().includes(e.target.value.toUpperCase())));
        }
    }

    function handleDelete(e, id) {
        e.preventDefault();
        let url = "https://api.poddies-kochbuch.de/categories/" + id;

        if(window.confirm("You are about to delete this category. Are you sure?")) {
            fetch(url, {
                method: "DELETE",
                headers: {
                    'Authorization': userContext.user.loginAns.token_type + " " + userContext.user.loginAns.access_token,
                },
            })
            .then((res) => {
                if(res.status === 200) { 
                    toast.success("Category deleted successfully");
                    setList(list.filter(element => element.idCategory !== id));
                    setBaselist(baselist.filter(element => element.idCategory !== id));
                }
                else {
                    toast.error("Failed to delete Category")
                }
            });
        }
    }

    function handleEdit(e, id) {
        e.preventDefault();
        let category = baselist.filter(element => element.idCategory === id);
        showWidget(category[0].catname, category[0].idCategory)
    }

    function handleCatSave(catname, catid) {

        if(typeof catid === "undefined") {
            let newurl = 'https://api.poddies-kochbuch.de/categories?name=' + catname;

            fetch(newurl, {
                method: "POST",
                headers: {
                    'Authorization': userContext.user.loginAns.token_type + " " + userContext.user.loginAns.access_token,
                    'Content-Type': 'application/json'
                }
            })
            .then(res => {
                if(res.status !== 200) {
                    toast.error("Failed to Create Category");
                }
                else {
                    toast.success("Category " + catname + " created successfully");
                    setCatwidgetname(null);
                    res.json()
                    .then(json => {
                        setBaselist([
                                    ...baselist,
                                    {
                                        count: 0,
                                        idCategory: json.newId,
                                        catname: catname,
                                        recipes: [],
                                        username: userContext.user.loginAns.user
                                    }
                                    ]
                        );
                        setList([
                                ...baselist,
                                {
                                    count: 0,
                                    idCategory: json.newId,
                                    catname: catname,
                                    recipes: [],
                                    username: userContext.user.loginAns.user
                                }
                                ]
                        );
                    });
                }
            });
            
        }
        else {
            let newurl = 'https://api.poddies-kochbuch.de/categories/' + catid + '?name=' + catname;

            fetch(newurl, {
                method: "PUT",
                headers: {
                    'Authorization': userContext.user.loginAns.token_type + " " + userContext.user.loginAns.access_token,
                    'Content-Type': 'application/json'
                }
            })
            .then(res => {
                if(res.status !== 200) {
                    toast.error("Failed to Update Category");
                }
                else {
                    toast.success("Category " + catname + " updated successfully");
                    setCatwidgetname(null);
                    res.json()
                    .then(json => {
                        let cat = baselist.filter(element => element.idCategory === catid);
                        let pos = baselist.indexOf(cat[0]);
                        setBaselist([
                                    ...baselist.slice(0,pos),
                                    {
                                        ...cat[0],
                                        catname: catname
                                    },
                                    ...baselist.slice(pos + 1)
                                    ]
                        );
                        setList([
                                ...baselist.slice(0,pos),
                                {
                                    ...cat[0],
                                    catname: catname
                                },
                                ...baselist.slice(pos + 1)
                                ]
                        );
                    });
                }
            });
        }
    }
}


/*
class CategoryList extends React.Component{
    static contextType = UserContext

    constructor(props) {
        super(props)
        this.state = {list: [],
                      baselist: [],
                      showwidget: false,
                      catwidgetname: null,
                      catwidgetid: null}

        this.hideWidget = this.hideWidget.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.handleCatSave = this.handleCatSave.bind(this);

    }

    componentDidMount() {
        const userData = this.context.user

        fetch('https://api.poddies-kochbuch.de/categories', {
            headers: {
                'Authorization': userData.loginAns.token_type + " " + userData.loginAns.access_token,
            },
        })
            .then(res => res.json())
            .then(json => {
                this.setState({list: json,
                                baselist: json})
            });

    }

    showWidget(catwidgetname, catwidgetid) {
        this.setState({catwidgetname: catwidgetname})
        this.setState({catwidgetid: catwidgetid})
        this.setState({showwidget: true})
        
    }

    hideWidget() {
        this.setState({showwidget: false})
        this.setState({catwidgetname: null})
        this.setState({catwidgetid: null})
    }

    handleSearch(e) {
        if(e.target.value === "") {
            this.setState({list: this.state.baselist});
        }
        else {
            this.setState({list: this.state.baselist.filter(element => element.catname.toUpperCase().includes(e.target.value.toUpperCase()))});
        }
    }

    handleDelete(e, id) {
        e.preventDefault();
        const userData = this.context.user;
        let url = "https://api.poddies-kochbuch.de/categories/" + id;

        if(window.confirm("You are about to delete this category. Are you sure?")) {
            fetch(url, {
                method: "DELETE",
                headers: {
                    'Authorization': userData.loginAns.token_type + " " + userData.loginAns.access_token,
                },
            })
            .then((res) => {
                if(res.status === 200) { 
                    toast.success("Category deleted successfully");
                    this.setState({list: this.state.list.filter(element => element.idCategory !== id)});
                    this.setState({baselist: this.state.baselist.filter(element => element.idCategory !== id)});
                }
                else {
                    toast.error("Failed to delete Category")
                }
            });
        }
    }

    handleEdit(e, id) {
        e.preventDefault();
        let category = this.state.baselist.filter(element => element.idCategory === id);
        this.showWidget(category[0].catname, category[0].idCategory)
    }

    handleCatSave(catname, catid) {
        const userData = this.context.user

        if(typeof catid === "undefined") {
            let newurl = 'https://api.poddies-kochbuch.de/categories?name=' + catname;

            fetch(newurl, {
                method: "POST",
                headers: {
                    'Authorization': userData.loginAns.token_type + " " + userData.loginAns.access_token,
                    'Content-Type': 'application/json'
                }
            })
            .then(res => {
                if(res.status !== 200) {
                    toast.error("Failed to Create Category");
                }
                else {
                    toast.success("Category " + catname + " created successfully");
                    this.setState({catwidgetname: null});
                    res.json()
                    .then(json => {
                        this.setState({baselist: [
                                            ...this.state.baselist,
                                            {
                                                count: 0,
                                                idCategory: json.newId,
                                                catname: catname,
                                                recipes: [],
                                                username: userData.loginAns.user
                                            }
                                            ]
                        });
                        this.setState({list: [
                                        ...this.state.baselist,
                                        {
                                            count: 0,
                                            idCategory: json.newId,
                                            catname: catname,
                                            recipes: [],
                                            username: userData.loginAns.user
                                        }
                                        ]
                        });
                    });
                }
            });
            
        }
        else {
            let newurl = 'https://api.poddies-kochbuch.de/categories/' + catid + '?name=' + catname;

            fetch(newurl, {
                method: "PUT",
                headers: {
                    'Authorization': userData.loginAns.token_type + " " + userData.loginAns.access_token,
                    'Content-Type': 'application/json'
                }
            })
            .then(res => {
                if(res.status !== 200) {
                    toast.error("Failed to Update Category");
                }
                else {
                    toast.success("Category " + catname + " updated successfully");
                    this.setState({catwidgetname: null});
                    res.json()
                    .then(json => {
                        let cat = this.state.baselist.filter(element => element.idCategory === catid);
                        let pos = this.state.baselist.indexOf(cat[0]);
                        this.setState({baselist: [
                                            ...this.state.baselist.slice(0,pos),
                                            {
                                                ...cat[0],
                                                catname: catname
                                            },
                                            ...this.state.baselist.slice(pos + 1)
                                            ]
                        });
                        this.setState({list: [
                                            ...this.state.baselist.slice(0,pos),
                                            {
                                                ...cat[0],
                                                catname: catname
                                            },
                                            ...this.state.baselist.slice(pos + 1)
                                            ]
                        });
                    });
                }
            });
        }
    }

    render() {
        let recipes = this.state.list.map((el, i) => (
            <CategoryCard key={el.idCategory} id={el.idCategory} name={el.catname} count={el.count} firstrec={el.recipes[0]} handleDelete={this.handleDelete} handleEdit={this.handleEdit}/>
        ))

        return(
            <>
                <h1>Hier sehen Sie alle Ihre Kategorien:</h1> 
                <div className='katlisttoolbar'>
                    <button className='catlistbtn' onClick={e => this.showWidget()}>New +</button>
                    <input className="searchbar" type="text" placeholder="Search..." value={this.state.search} onChange={(e) => this.handleSearch(e)}/>
                </div>
                <div className='categorylist'>
                    {recipes}
                </div>
                <AddCatWidget visible={this.state.showwidget} hide={this.hideWidget} name={this.state.catwidgetname} catid={this.state.catwidgetid} handleSave={this.handleCatSave}/>
            </>
        );
    }
}

export default CategoryList;
*/