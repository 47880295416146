import React from 'react'
import "./aboutus.scss";

export default function AboutUs() {
    return(
        <> 
            <p>
                Diese Seite ist ein Projekt um die Entwicklung mit dem Framework React zu lernen. <br/>
                Außerdem wurde diese Seite erstellt, um eine flexible alternative zu anderen digitalen Kochbüchern zu bieten. <br/>
                Es können Rezepte einfach und schnell erstellt und veröffentlicht werden, wodurch das Aufbauen eines eigenen Kochbuchs deutlich einfacher ist. <br/>
                Außerdem können die Rezepte in eigene Kategorien eingeteilt werden, wodurch jeder Benutzer seine Rezeptlisten einfach verwalten kann. <br/>
                Für das Erstellen von Rezepten und Kategorien wird ein Account benötigt. <br/>
                <br/>
                Bei dieser Seite handelt es sich um ein reines Lern- und Spaßprojekt des Entwicklers. Alle Funktionen dieser Seite sind kostenlos. <br/>

            </p>
            <h3>Features</h3>
            <details>
                <summary>
                    Rezepte suchen
                </summary>
                <p>
                    Alle auf dieser Seite erstellten Rezepte lassen sich über die Suche finden. <br/>
                    Aktuell nutzbare Suchparameter:
                    <ul>
                        <li>Rezeptname</li>
                        <li>Ersteller</li>
                        <li>Nur eigene Rezepte</li>
                        <li>Vegan</li>
                        <li>Vegetarisch</li>
                    </ul>
                    <br/>
                </p>
            </details>
            <details>
                <summary>
                    Zutaten Suche
                </summary>
                <p>
                    Sie können eine oder mehrere Zutaten auswählen und dann alle Rezepte suchen, die diese Zutaten beinhalten. <br/>
                </p>
            </details>
            <details>
                <summary>
                    Eigene Rezepte verwalten
                </summary>
                <p>
                    <strong><em>Hierfür wird ein eigener Account benötigt</em></strong><br/>
                    Sie können Ihre eigenen Rezepte erstellen und verwalten. Nach der Erstellung können die Rezepte jederzeit bearbeitet und wieder gelöscht werden. <br/>
                    Die Reihenfolge der Zutaten lässt sich jederzeit per Drag & Drop ändern. Außerdem können Sie Zwischenüberschriften bei den Zutaten erstellen. <br/>
                    Bedenken Sie beim Erstellen eines Rezeptes an die Nutzbarkeit anderer Funktionen.<br/>
                    Zum Beispiel die Nutzbarkeit der Zutaten Suche.<br/>
                </p>
            </details>
            <details>
                <summary>
                    Persönliche Notizen
                </summary>
                <p>
                    <strong><em>Hierfür wird ein eigener Account benötigt</em></strong><br/>
                    Sie können bei jedem Rezept persönliche Notizen erstellen, um sich Hinweise für die Zubereitung zu merken.<br/>
                    Ihre persönlichen Notizen sind nur für Sie sichtbar.<br/>
                </p>
            </details>
            <details>
                <summary>
                    Eigene Kategorien verwalten
                </summary>
                <p>
                    <strong><em>Hierfür wird ein eigener Account benötigt</em></strong><br/>
                    Sie können Ihre eigenen Kategorien erstellen und verwalten. Nach der Erstellung können die Kategorien jederzeit bearbeitet und wieder gelöscht werden. <br/>
                    Sie können je nach Belieben Rezepte einer Kategorie hinzufügen und Sie wieder entfernen. <br/>
                    Die von Ihnen erstellten Kategorien sind nur für Sie sichtbar. <br/>
                </p>
            </details>
            <details>
                <summary>
                    Theming
                </summary>
                <p>
                    <strong><em>Hierfür wird ein eigener Account benötigt</em></strong><br/>
                    Sie können in Ihren Benutzereinstellungen zwischen dem voreingestelltem Light oder Dark Theme auswählen. <br/>
                    Alternativ können sie eine komplett eigene Farbgebung aussuchen. <br/>
                </p>
            </details>
            <details>
                <summary>
                    Offene API
                </summary>
                <p>
                    Dieser Seite zugrunde liegt eine mit dem Python Framwork fastapi geschriebene API. <br/>
                    <table>
                        <tbody>
                            <tr>
                                <td>Endpoint:</td>
                                <td><a href="https://api.poddies-kochbuch.de">api.poddies-kochbuch.de</a></td>
                            </tr>
                            <tr>
                                <td>Docs:</td>
                                <td><a href="https://api.poddies-kochbuch.de/docs">api.poddies-kochbuch.de/docs</a></td>
                            </tr>
                        </tbody>
                    </table>
                    <br/>
                </p>
            </details>
            <h3>Geplante Features</h3>
            <ul>
                <li>Rezept Suche über Zutaten - Wildcards / Design Update</li>
                <li>Übertragen von Zutaten auf Einkaufslisten (Alexa)</li>
                <li>Android App</li>
            </ul>
        </>
    )
}



/*
class AboutUs extends React.Component{

    render() {
        return(
            <> 
                <p>
                    Diese Seite ist ein Projekt um die Entwicklung mit dem Framework React zu lernen. <br/>
                    Außerdem wurde diese Seite erstellt, um eine flexible alternative zu anderen digitalen Kochbüchern zu bieten. <br/>
                    Es können Rezepte einfach und schnell erstellt und veröffentlicht werden, wodurch das Aufbauen eines eigenen Kochbuchs deutlich einfacher ist. <br/>
                    Außerdem können die Rezepte in eigene Kategorien eingeteilt werden, wodurch jeder Benutzer seine Rezeptlisten einfach verwalten kann. <br/>
                    Für das Erstellen von Rezepten und Kategorien wird ein Account benötigt. <br/>
                    <br/>
                    Bei dieser Seite handelt es sich um ein reines Lern- und Spaßprojekt des Entwicklers. Alle Funktionen dieser Seite sind kostenlos. <br/>

                </p>
                <h3>Features</h3>
                <details>
                    <summary>
                        Rezepte suchen
                    </summary>
                    <p>
                        Alle auf dieser Seite erstellten Rezepte lassen sich über die Suche finden. <br/>
                        Aktuell nutzbare Suchparameter:
                        <ul>
                            <li>Rezeptname</li>
                            <li>Ersteller</li>
                            <li>Nur eigene Rezepte</li>
                            <li>Vegan</li>
                            <li>Vegetarisch</li>
                        </ul>
                        <br/>
                    </p>
                </details>
                <details>
                    <summary>
                        Zutaten Suche
                    </summary>
                    <p>
                        Sie können eine oder mehrere Zutaten auswählen und dann alle Rezepte suchen, die diese Zutaten beinhalten. <br/>
                    </p>
                </details>
                <details>
                    <summary>
                        Eigene Rezepte verwalten
                    </summary>
                    <p>
                        <strong><em>Hierfür wird ein eigener Account benötigt</em></strong><br/>
                        Sie können Ihre eigenen Rezepte erstellen und verwalten. Nach der Erstellung können die Rezepte jederzeit bearbeitet und wieder gelöscht werden. <br/>
                        Bedenken Sie beim Erstellen eines Rezeptes an die Nutzbarkeit anderer Funktionen.<br/>
                        Zum Beispiel die Nutzbarkeit der Zutaten Suche.<br/>
                    </p>
                </details>
                <details>
                    <summary>
                        Persönliche Notizen
                    </summary>
                    <p>
                        <strong><em>Hierfür wird ein eigener Account benötigt</em></strong><br/>
                        Sie können bei jedem Rezept persönliche Notizen erstellen, um sich Hinweise für die Zubereitung zu merken.<br/>
                        Ihre persönlichen Notizen sind nur für Sie sichtbar.<br/>
                    </p>
                </details>
                <details>
                    <summary>
                        Eigene Kategorien verwalten
                    </summary>
                    <p>
                        <strong><em>Hierfür wird ein eigener Account benötigt</em></strong><br/>
                        Sie können Ihre eigenen Kategorien erstellen und verwalten. Nach der Erstellung können die Kategorien jederzeit bearbeitet und wieder gelöscht werden. <br/>
                        Sie können je nach Belieben Rezepte einer Kategorie hinzufügen und Sie wieder entfernen. <br/>
                        Die von Ihnen erstellten Kategorien sind nur für Sie sichtbar. <br/>
                    </p>
                </details>
                <details>
                    <summary>
                        Theming
                    </summary>
                    <p>
                        <strong><em>Hierfür wird ein eigener Account benötigt</em></strong><br/>
                        Sie können in Ihren Benutzereinstellungen zwischen dem voreingestelltem Light oder Dark Theme auswählen. <br/>
                        Alternativ können sie eine komplett eigene Farbgebung aussuchen. <br/>
                    </p>
                </details>
                <details>
                    <summary>
                        Offene API
                    </summary>
                    <p>
                        Dieser Seite zugrunde liegt eine mit dem Python Framwork fastapi geschriebene API. <br/>
                        <table>
                            <tbody>
                                <tr>
                                    <td>Endpoint:</td>
                                    <td><a href="https://api.poddies-kochbuch.de">api.poddies-kochbuch.de</a></td>
                                </tr>
                                <tr>
                                    <td>Docs:</td>
                                    <td><a href="https://api.poddies-kochbuch.de/docs">api.poddies-kochbuch.de/docs</a></td>
                                </tr>
                            </tbody>
                        </table>
                        <br/>
                    </p>
                </details>
                <h3>Geplante Features</h3>
                <ul>
                    <li>Rezept Suche über Zutaten - Wildcards / Design Update</li>
                    <li>Übertragen von Zutaten auf Einkaufslisten (Alexa)</li>
                    <li>Android App</li>
                </ul>
            </>
        )
    }
}

export default AboutUs;
*/