import React from "react";
import Recipelist from "../recipelist/recipelist";
import { useParams } from "react-router-dom";

export default function CatRecipeList(props) {

    const {category} = useParams();

    return (
        <>
            <h1>Hier sind alle Rezepte der Kategorie</h1>
            <Recipelist catid={category}/>
        </>
    )

}


/*
class CatRecipeList extends React.Component {


    render() {
        return (
            <>
                <h1>Hier sind alle Rezepte der Kategorie</h1>
                <Recipelist catid={this.props.params.category}/>
            </>
        )
    }
}

function withParams(Component) {
    return props => <CatRecipeList params={useParams()} />;
  }

export default withParams(CatRecipeList);
*/