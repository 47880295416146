import React, { useContext, useEffect, useState } from 'react';
import { useNavigate} from "react-router-dom";
import RecipeCard from './recipecard';
import './recipelist.scss'
import UserContext from '../../UserContext';

export default function RecipeList(props) {

    const userContext = useContext(UserContext);
    const navigate = useNavigate();

    const [list, setList] = useState([]);
    const [baselist, setBaselist] = useState([]);
    const [showFilter, setShowFilter] = useState(false);
    const [newFilter, setNewFilter] = useState(false);
    const [filter, setFilter] = useState({
                                        search: "",
                                        onlyOwn: false,
                                        vegetary: false,
                                        vegan: false,
                                        creator: "",
                                        fromDate: "",
                                        toDate: "",
                                    });


    useEffect(() => {

        if(window.location.pathname === "/alle-rezepte") {
            fetch('https://api.poddies-kochbuch.de/recipes')
                .then(res => res.json())
                .then(json => {
                    setList(json);
                    setBaselist(json);
                });
        }
        
        else {
            let url = 'https://api.poddies-kochbuch.de/recipes?category=' + props.catid;
            fetch(url)
                .then(res => res.json())
                .then(json => {
                    setList(json);
                    setBaselist(json);
                });
        }
    }, [props.catid]);

    useEffect(() => {
        if(window.location.pathname === "/zutatensuche") {
            setList(props.reclist);
            setBaselist(props.reclist);
        }
    }, [props.reclist])


    function handleNewRec() {
        let url = 'https://api.poddies-kochbuch.de/recipes';
        
        fetch(url, {
            method: "POST",
            headers: {
                'Authorization': userContext.user.loginAns.token_type + " " + userContext.user.loginAns.access_token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({name: "",
                                worksteps: "",
                                reference: "",
                                baseamount: 4,
                                shortdesc: "",
                                vegetary: false,
                                vegan: false,
        })})
        .then(res => res.json())
        .then(json =>
            navigate("/rezept/" + json.insert_id)
            );
    }

    function handleRandom() {
        var rec = list[Math.floor(Math.random()*list.length)];
        navigate("/rezept/" + rec.idRecipe)
    }

    function applyFilter() {
        let filteredList = baselist.filter(element => {
            if(filter.search !== "" && !element.name.toUpperCase().includes(filter.search.toUpperCase())) {
                return null;
            }

            if(filter.creator !== "" && !filter.onlyOwn && !element.creator.includes(filter.creator)) {
                return null;
            }

            if(filter.onlyOwn && element.creator !== userContext.user.user) {
                return null;
            } 

            if(filter.vegetarisch && !element.vegetary) {
                return null;
            } 

            if(filter.vegan && !element.vegan) {
                return null;
            }

            if(filter.fromDate !== "" && filter.fromDate > new Date(element.creationdate)) {
                return null;
            }

            if(filter.toDate !== "" && filter.toDate < new Date(element.creationdate)) {
                return null;
            }

            return element;
        });

        setList(filteredList);
        setNewFilter(false);
    }



    let recipes = list.map((el, i) => (
        <RecipeCard key={el.idRecipe} id={el.idRecipe} name={el.name} shortdesc={el.shortdesc} />
    ));

    if(newFilter) {
        applyFilter();
    }

    return(
        <>
            <div className='reclist_wrap'>
                <div className='reclisttoolbar'>
                    <button className='reclisttoolbtn filter_btn' onClick={(e) => setShowFilter(!showFilter)}>
                    </button>
                    <button style={{visibility: (!window.location.pathname.includes("/kategorien/") && !(window.location.pathname === "/zutatensuche") && userContext.user.isLoggedIn) ? "initial" : "hidden"}} disabled={!(!window.location.pathname.includes("/kategorien/") && !(window.location.pathname === "/zutatensuche")  && userContext.user.isLoggedIn)} className='reclisttoolbtn' onClick={(e) => handleNewRec()} >
                        New +
                    </button>
                    <input className="searchbar" type="text" placeholder="Search..." value={filter.search} onChange={(e) => {setFilter({ ...filter, search: e.target.value}); setNewFilter(true)}}/>
                </div>
                <div className='filterarea' style={{display: showFilter ? "initial" : "none"}}>
                    <table>
                        <tbody>
                            <tr>
                                <td><label htmlFor='creator'>Ersteller:</label></td>
                                <td><input id="creator" type="text" disabled={filter.onlyOwn} onChange={(e) => {setFilter({ ...filter, creator: e.target.value}); setNewFilter(true)}}></input></td>
                                <td><input type="checkbox" id="filter_usr" onChange={(e) => {setFilter({ ...filter, onlyOwn: e.target.checked}); setNewFilter(true)}} /></td>
                                <td><label htmlFor='filter_usr'>Nur eigene</label></td>
                                <td colSpan={2}><label><b>Erstelldatum</b></label></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td><input type="checkbox" id="filter_vege" onChange={(e) => {setFilter({ ...filter, vegetarisch: e.target.checked}); setNewFilter(true)}} /></td>
                                <td><label htmlFor='filter_vege'>Vegetarisch</label></td>
                                <td><label htmlFor='filter_fromDate'>Von:</label></td>
                                <td><input is="filter_fromDate" type='date' onChange={(e) => {setFilter({ ...filter, fromDate: new Date(e.target.value)}); setNewFilter(true)}}></input></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td><input type="checkbox" id="filter_vega" onChange={(e) => {setFilter({ ...filter, vegan: e.target.checked}); setNewFilter(true)}} /></td>
                                <td><label htmlFor='filter_vega'>Vegan</label></td>
                                <td><label htmlFor='filter_toDate'>Bis:</label></td>
                                <td><input id="filter_toDate" type='date' onChange={(e) => {setFilter({ ...filter, toDate: new Date(e.target.value)}); setNewFilter(true)}}></input></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="recipelist">
                    {recipes}
                </div>
                <button className="randbtn" onClick={() => handleRandom()}></button>
            </div>
        </>
    );
}





/*
class RecipeList extends React.Component{
    static contextType = UserContext

    constructor(props) {
        super(props)
        this.state = {list: [],
                      baselist: [],
                      showfilter: false,
                      filter: {
                        search: "",
                        onlyOwn: false,
                        vegetary: false,
                        vegan: false,
                        creator: "",
                        fromDate: "",
                        toDate: "",
                      }}

    }

    componentDidMount() {
        
        if(window.location.pathname === "/alle-rezepte") {
            fetch('https://api.poddies-kochbuch.de/recipes')
                .then(res => res.json())
                .then(json => {
                    this.setState({list: json,
                                    baselist: json})
                });
        }
        
        else {
            let url = 'https://api.poddies-kochbuch.de/recipes?category=' + this.props.catid;
            fetch(url)
                .then(res => res.json())
                .then(json => {
                    this.setState({list: json,
                                    baselist: json})
                });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if(window.location.pathname === "/zutatensuche" && prevProps.reclist !== this.props.reclist) {
            this.setState({
                list: this.props.reclist,
                baselist: this.props.reclist
            });
        }
      }

    handleNewRec() {
        const userData = this.context.user

        let url = 'https://api.poddies-kochbuch.de/recipes';
        
        fetch(url, {
            method: "POST",
            headers: {
                'Authorization': userData.loginAns.token_type + " " + userData.loginAns.access_token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({name: "",
                                worksteps: "",
                                reference: "",
                                baseamount: 4,
                                shortdesc: "",
                                vegetary: false,
                                vegan: false,
        })})
        .then(res => res.json())
        .then(json =>
            this.props.navigate("/rezept/" + json.insert_id)
            );
    }

    handleRandom() {
        var rec = this.state.list[Math.floor(Math.random()*this.state.list.length)];
        this.props.navigate("/rezept/" + rec.idRecipe)
    }

    applyFilter() {
        const userData = this.context.user;

        let filteredList = this.state.baselist.filter(element => {
            if(this.state.filter.search !== "" && !element.name.toUpperCase().includes(this.state.filter.search.toUpperCase())) {
                return null;
            }

            if(this.state.filter.creator !== "" && !this.state.filter.onlyOwn && !element.creator.includes(this.state.filter.creator)) {
                return null;
            }

            if(this.state.filter.onlyOwn && element.creator !== userData.user) {
                return null;
            } 

            if(this.state.filter.vegetarisch && !element.vegetary) {
                return null;
            } 

            if(this.state.filter.vegan && !element.vegan) {
                return null;
            }

            if(this.state.filter.fromDate !== "" && this.state.filter.fromDate > new Date(element.creationdate)) {
                return null;
            }

            if(this.state.filter.toDate !== "" && this.state.filter.toDate < new Date(element.creationdate)) {
                return null;
            }

            return element;
        });

        this.setState({list: filteredList,
                        newFilter: false});
    }

    render() {
        const userData = this.context.user

        let recipes = this.state.list.map((el, i) => (
            <RecipeCard key={el.idRecipe} id={el.idRecipe} name={el.name} shortdesc={el.shortdesc} />
        ));

        if(this.state.newFilter) {
            this.applyFilter();
        }

        return(
            <>
                <div className='reclist_wrap'>
                    <div className='reclisttoolbar'>
                        <button className='reclisttoolbtn filter_btn' onClick={(e) => this.setState({showfilter: !this.state.showfilter})}>
                        </button>
                        <button style={{visibility: (!window.location.pathname.includes("/kategorien/") && !(window.location.pathname === "/zutatensuche") && userData.isLoggedIn) ? "initial" : "hidden"}} disabled={!(!window.location.pathname.includes("/kategorien/") && !(window.location.pathname === "/zutatensuche")  && userData.isLoggedIn)} className='reclisttoolbtn' onClick={(e) => this.handleNewRec()} >
                            New +
                        </button>
                        <input className="searchbar" type="text" placeholder="Search..." value={this.state.filter.search} onChange={(e) => this.setState({filter: { ...this.state.filter, search: e.target.value}, newFilter: true})}/>
                    </div>
                    <div className='filterarea' style={{display: this.state.showfilter ? "initial" : "none"}}>
                        <table>
                            <tbody>
                                <tr>
                                    <td><label htmlFor='creator'>Ersteller:</label></td>
                                    <td><input id="creator" type="text" disabled={this.state.filter.onlyOwn} onChange={(e) => this.setState({filter: { ...this.state.filter, creator: e.target.value}, newFilter: true})}></input></td>
                                    <td><input type="checkbox" id="filter_usr" onChange={(e) => this.setState({filter: { ...this.state.filter, onlyOwn: e.target.checked}, newFilter: true})} /></td>
                                    <td><label htmlFor='filter_usr'>Nur eigene</label></td>
                                    <td colSpan={2}><label><b>Erstelldatum</b></label></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td><input type="checkbox" id="filter_vege" onChange={(e) => this.setState({filter: { ...this.state.filter, vegetarisch: e.target.checked}, newFilter: true})} /></td>
                                    <td><label htmlFor='filter_vege'>Vegetarisch</label></td>
                                    <td><label htmlFor='filter_fromDate'>Von:</label></td>
                                    <td><input is="filter_fromDate" type='date' onChange={(e) => this.setState({filter: { ...this.state.filter, fromDate: new Date(e.target.value)}, newFilter: true})}></input></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td><input type="checkbox" id="filter_vega" onChange={(e) => this.setState({filter: { ...this.state.filter, vegan: e.target.checked}, newFilter: true})} /></td>
                                    <td><label htmlFor='filter_vega'>Vegan</label></td>
                                    <td><label htmlFor='filter_toDate'>Bis:</label></td>
                                    <td><input id="filter_toDate" type='date' onChange={(e) => this.setState({filter: { ...this.state.filter, toDate: new Date(e.target.value)}, newFilter: true})}></input></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="recipelist">
                        {recipes}
                    </div>
                    <button className="randbtn" onClick={() => this.handleRandom()}></button>
                </div>
            </>
        );
    }
}

function withHooks(Component) {
    return props => <RecipeList navigate={useNavigate()} {...props} />;
}

export default withHooks(RecipeList);
*/