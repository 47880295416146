import React, { useEffect, useState } from "react";
import "./ingredientsearch.scss"
import Recipelist from "../recipelist/recipelist";

export default function IngredientSearch() {

    const [ingredients,setIngredients] = useState([]);
    const [ingredient,setIngredient] = useState("");
    const [recipes,setRecipes] = useState([]);
    const [possIngList,setPossIngList] = useState([]);
    let ingredientShow = [];
    let ingdatalist = [];

    useEffect(() => {
        let url = "https://api.poddies-kochbuch.de/all_ingredient_names";
        fetch(url)
            .then(res => res.json())
            .then(json => setPossIngList(json));
    }, [])

    

    ingredients.forEach((element, i) => {
        ingredientShow.push(
            <div className="ingredientShow" key={i}>
                {element}
                <button onClick={e => 
                    setIngredients(
                        [
                            ...ingredients.slice(0,i),
                            ...ingredients.slice(i+1)
                        ]
                    )
                }>x</button>
            </div>
        );
    });


    possIngList.forEach(element => {
        ingdatalist.push(<option key={element} value={element} />)
    });

    return(
        <div className="ingredientSearch">
            <input type="text" id="ingredientSearchBar" className="ingredientSearchBar" list="inglist" value={ingredient} onChange={e => setIngredient(e.target.value)}></input>
            <datalist id="inglist">
                {ingdatalist}
            </datalist>
            <button className="addIngButton" onClick={e => addIng()}>Add</button>
            <button className="searchButton" onClick={e => handleSearch()}>Search</button>
            <div className="ingredientShowList">
                {ingredientShow}
            </div>
            <h2>Hier sehen sie alle Rezept zu den von Ihnen ausgewählten Zutaten:</h2>
            <Recipelist reclist={recipes}/>
        </div>
    );

    function addIng() {
        setIngredients([
            ...ingredients,
            ingredient
        ]);
        setIngredient("");
    }

    function handleSearch() {
        let url = 'https://api.poddies-kochbuch.de/recipes?';
        ingredients.forEach(element => {
            url = url.concat("ingredients=" + element + "&");
        });
        fetch(url)
            .then(res => res.json())
            .then(json => setRecipes(json));
    }

}




/*
class IngredientSearch extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            ingredients: [],
            ingredient: "",
            recipes: [],
            possIngList: [],
        }
    }

    componentDidMount() {
        let url = "https://api.poddies-kochbuch.de/all_ingredient_names";
        fetch(url)
            .then(res => res.json())
            .then(json => this.setState({possIngList: json}));
    }

    addIng() {
        this.setState({ingredients: [
            ...this.state.ingredients,
            this.state.ingredient
        ]});
        this.setState({ingredient: ""});
    }

    handleSearch() {
        let url = 'https://api.poddies-kochbuch.de/recipes?';
        this.state.ingredients.forEach(element => {
            url = url.concat("ingredients=" + element + "&");
        });
        fetch(url)
            .then(res => res.json())
            .then(json => this.setState({recipes: json}));
    }

    render() {

        let ingredientShow = [];
        this.state.ingredients.forEach((element, i) => {
            ingredientShow.push(
                <div className="ingredientShow" key={i}>
                    {element}
                    <button onClick={e => 
                        this.setState({
                            ingredients: [
                                ...this.state.ingredients.slice(0,i),
                                ...this.state.ingredients.slice(i+1)
                            ]
                        })
                    }>x</button>
                </div>
            );
        });

        let ingdatalist = [];
        this.state.possIngList.forEach(element => {
            ingdatalist.push(<option key={element} value={element} />)
        });


        return(
            <div className="ingredientSearch">
                <input type="text" id="ingredientSearchBar" className="ingredientSearchBar" list="inglist" value={this.state.ingredient} onChange={e => this.setState({ingredient: e.target.value})}></input>
                <datalist id="inglist">
                    {ingdatalist}
                </datalist>
                <button className="addIngButton" onClick={e => this.addIng()}>Add</button>
                <button className="searchButton" onClick={e => this.handleSearch()}>Search</button>
                <div className="ingredientShowList">
                    {ingredientShow}
                </div>
                <h2>Hier sehen sie alle Rezept zu den von Ihnen ausgewählten Zutaten:</h2>
                <Recipelist reclist={this.state.recipes}/>
            </div>
        );
    }

}

export default IngredientSearch;
*/